import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ChangePasswordForm from '../components/account-setup/ChangePasswordForm'
import PushNotificationForm from '../components/account-setup/PushNotificationForm'
import TeamProfileForm from '../components/account-setup/TeamProfileForm'
import SettingNavigation from '../components/dashboard/SettingNavigation'

const PushNotificationPage = () => {
    return (
        <div className='push-notification-page pb-3'>
            <Row>
                <Col xs={12} className="d-block d-md-none mt-4">
                    <h5 className='text-yellow'>SETTINGS</h5>
                </Col>

                <SettingNavigation />

                <Col xs={12} lg={6} xl={4} className="mt-4">
                    <PushNotificationForm />
                </Col>
            </Row>
        </div>
    )
}

export default PushNotificationPage