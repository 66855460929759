import React, { useContext } from 'react'
import { Button, Carousel, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import Assets from '../../constants/images'
import { AddUserIcon, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, TwitterIcon, YoutubeIcon, YoutubeIconWithoutBg } from '../../constants/svgs'
import { Controller, useForm } from "react-hook-form";
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { FilterPopupContext } from '../../App';

const FilterSearchPopup = () => {
    const [handleFilterPopup, setHandleFilterPopup] = useContext(FilterPopupContext);
    const { register, handleSubmit, reset, watch, control, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = (data) => {
        console.log(data);
        setHandleFilterPopup(false)
        // reset();
        // success("Password has been updated")
    }

    return (
        <div>
            <Modal
                className='filter-search-modal'
                show={handleFilterPopup}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                        <h6 className='p-0 m-0'>Filter Your Search</h6>
                        <div onClick={()=>setHandleFilterPopup(false)} className="d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    </div>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <div className="pb-4 border-bottom mb-3">
                                <h6 className='p-0 m-0'>Platform</h6>
                                <div className='socialCheckbox d-flex flex-wrap'>
                                    <div className="mt-2">
                                        <input type="checkbox" id='facebook' {...register("socialPlatform")} className='' />
                                        <label htmlFor="facebook" className='d-flex align-items-center justify-content-center text-center label-facebook'><FacebookIcon /></label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='twitter' {...register("socialPlatform")} className='' />
                                        <label htmlFor="twitter" className='d-flex align-items-center justify-content-center text-center label-twitter'><TwitterIcon /></label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='instagram' {...register("socialPlatform")} className='' />
                                        <label htmlFor="instagram" className='d-flex align-items-center justify-content-center text-center label-instagram'><InstagramIcon /></label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='linkedin' {...register("socialPlatform")} className='' />
                                        <label htmlFor="linkedin" className='d-flex align-items-center justify-content-center text-center label-linkedin'><LinkedinIcon /></label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='youtube' {...register("socialPlatform")} className='' />
                                        <label htmlFor="youtube" className='d-flex align-items-center justify-content-center text-center label-youtube'><YoutubeIconWithoutBg /></label>
                                    </div>
                                </div>
                            </div>

                            <div className="pb-4 border-bottom mb-3">
                                <h6 className='p-0 m-0'>Time Duration</h6>
                                <div className='otherCheckbox d-flex  flex-wrap'>
                                    <div className="mt-2">
                                        <input type="checkbox" id='all' {...register("timeDuration")} className='' />
                                        <label htmlFor="all" className='other-label'>All</label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='today' {...register("timeDuration")} className='' />
                                        <label htmlFor="today" className='other-label'>Today</label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='this week' {...register("timeDuration")} className='' />
                                        <label htmlFor="this week" className='other-label'>This Week</label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='last week' {...register("timeDuration")} className='' />
                                        <label htmlFor="last week" className='other-label'>Last Week</label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='this month' {...register("timeDuration")} className='' />
                                        <label htmlFor="this month" className='other-label'>This Month</label>
                                    </div>
                                </div>
                            </div>

                            <div className="pb-4 border-bottom mb-3">
                                <h6 className='p-0 m-0'>Content Type</h6>
                                <div className='otherCheckbox d-flex flex-wrap'>
                                    <div className='mt-2'>
                                        <input type="checkbox" id='photo' {...register("contentType")} className='' />
                                        <label htmlFor="photo" className='other-label'>Photo</label>
                                    </div>

                                    <div className='mt-2'>
                                        <input type="checkbox" id='video' {...register("contentType")} className='' />
                                        <label htmlFor="video" className='other-label'>Video</label>
                                    </div>

                                    <div className='mt-2'>
                                        <input type="checkbox" id='text' {...register("contentType")} className='' />
                                        <label htmlFor="text" className='other-label'>Text</label>
                                    </div>
                                </div>
                            </div>

                            <div className="pb-4 border-bottom mb-3">
                                <h6 className='p-0 m-0'>By Approval</h6>
                                <div className='otherCheckbox d-flex flex-wrap'>
                                    <div className='mt-2'>
                                        <input type="checkbox" id='approved post' {...register("postType")} className='' />
                                        <label htmlFor="approved post" className='other-label'>Approved Post</label>
                                    </div>

                                    <div className='mt-2'>
                                        <input type="checkbox" id='pending post' {...register("postType")} className='' />
                                        <label htmlFor="pending post" className='other-label'>Pending Post</label>
                                    </div>

                                    <div className='mt-2'>
                                        <input type="checkbox" id='rejected post' {...register("postType")} className='' />
                                        <label htmlFor="rejected post" className='other-label'>Rejected Post</label>
                                    </div>
                                </div>
                            </div>

                            <div className="pb-4 border-bottom mb-3">
                                <h6 className='p-0 m-0'>By Member</h6>
                                <div className='otherCheckbox d-flex flex-wrap'>
                                    <div className="mt-2">
                                        <input type="checkbox" id='john smith' {...register("member")} className='' />
                                        <label htmlFor="john smith" className='other-label'>John Smith</label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='jerry colin' {...register("member")} className='' />
                                        <label htmlFor="jerry colin" className='other-label'>jerry Colin</label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='valetina andrew' {...register("member")} className='' />
                                        <label htmlFor="valetina andrew" className='other-label'>Valetina Andrew</label>
                                    </div>
                                </div>
                            </div>

                            <div className="pb-4 border-bottom mb-3">
                                <div className='otherCheckbox d-flex flex-wrap'>
                                    <div className='mt-2'>
                                        <input type="checkbox" id='most recently scheduled' {...register("uploadStatus")} className='' />
                                        <label htmlFor="most recently scheduled" className='other-label'>Most Recently Scheduled</label>
                                    </div>

                                    <div className='mt-2'>
                                        <input type="checkbox" id='published posts' {...register("uploadStatus")} className='' />
                                        <label htmlFor="published posts" className='other-label'>Published Posts</label>
                                    </div>
                                </div>
                            </div>

                            <Button type="submit" className="w-100 mt-5 btn-create-post">FILTER NOW</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default FilterSearchPopup