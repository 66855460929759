export const VALIDATIONS = {
    NAME: 50,
    PHONE_MIN: 10,
    PHONE_MAX: 16,
    BIO_MAX: 255,
    EMAIL: 255,
    PASSWORD_MIN: 8,
    PASSWORD_MAX: 255,
    VERIFICATION_CODE: 5,
    CARD_NUMBER: 16,
    CVV: 3,
    TEAM_TITLE_MAX: 50,
    NOTIFICATION_TITLE_MAX: 50,
    NOTIFICATION_DESCRIPTION_MAX: 255,
    LOCATION_MAX: 255,
    CAPTION_MAX: 255,
}

export const VALIDATIONS_TEXT = {
    NAME_REQUIRED: "Name is required",
    EMAIL_REQUIRED: "Email is required",
    EMAIL_FORMAT: "Invalid email format",
    PASSWORD_REQUIRED: "Password is required",
    CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
    PHONE_REQUIRED: "Contact number is required",
    TEAM_TITLE_REQUIRED: "Team title is required",
    NOTIFICATION_TITLE_REQUIRED: "Notification title is required",
    NOTIFICATION_DESCRIPTION_REQUIRED: "Notification description is required",
    DATE_TIME_REQUIRED: "Date and time is required",
    SOCIAL_PLATFORM_REQUIRED: "Please select a platform",
    CARD_NUMBER_REQUIRED: "Card number is required",
    CVV_REQUIRED: "CVV is required",
    EXPIRY_DATE_REQUIRED: "Expiry date is required",

    NAME_MAX: "Max length is " + VALIDATIONS.NAME,
    EMAIL_MAX: "Max length is " + VALIDATIONS.EMAIL,
    PASSWORD_MIN: "Min length is " + VALIDATIONS.PASSWORD_MIN,
    PASSWORD_MAX: "Max length is " + VALIDATIONS.PASSWORD_MAX,
    CARD_NUMBER_MIN: "Min length is " + VALIDATIONS.CARD_NUMBER,
    CARD_NUMBER_MAX: "Max length is " + VALIDATIONS.CARD_NUMBER,
    PHONE_MIN: "Min length is " + VALIDATIONS.PHONE_MIN,
    PHONE_MAX: "Max length is " + VALIDATIONS.PHONE_MAX,
    BIO_MAX: "Max length is " + VALIDATIONS.BIO_MAX,
    CVV_MIN: "Min length is " + VALIDATIONS.CVV,
    CVV_MAX: "Max length is " + VALIDATIONS.CVV,
    TEAM_TITLE_MAX: "Max length is " + VALIDATIONS.TEAM_TITLE_MAX,
    NOTIFICATION_TITLE_MAX: "Max length is " + VALIDATIONS.NOTIFICATION_TITLE_MAX,
    NOTIFICATION_DESCRIPTION_MAX: "Max length is " + VALIDATIONS.NOTIFICATION_DESCRIPTION_MAX,
    LOCATION_MAX: "Max length is " + VALIDATIONS.LOCATION_MAX,
    CAPTION_MAX: "Max length is " + VALIDATIONS.CAPTION_MAX,
    VERIFICATION_CODE: "Length is " + VALIDATIONS.VERIFICATION_CODE,
    INVALID_FORMAT: "Invalid format",
}

export const SPECIAL_CHARACTER_ARRAY = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ",", ">", ".", "?", "/"
]

export const SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ">", ".", "?", "/"
]

export const CHARACTER_ARRAY_FOR_NUMBER_INPUT = [
    "e", "E", "-", "+", "."
]
