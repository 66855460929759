import React, { useEffect, useState } from 'react'
import { Form, Button, InputGroup, Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import Assets from "../../constants/images";
import { CameraIcon } from '../../constants/svgs';
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { FaTimes } from "react-icons/fa";

const TeamProfileForm = (props) => {
    const [showLocationPickerModal, setShowLocationPickerModal] = useState(false);
    const [preview, setPreview] = useState("")
    const navigate = useNavigate();
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const password = watch("password");

    const onSubmit = (data) => {
        success("Profile Updated")
        console.log(data)
        setShowLocationPickerModal(false)
    }

    const handleChange = (e) => {
        let img = e.target.files[0]
        setPreview(URL.createObjectURL(img))
    }

    let markersList = [
        { lat: 23.024000, lng: 72.580276 },
    ]
    let [markers, setMarkers] = useState(markersList);

    const mapStyles = {
        position: "relative",
        width: "100%",
        height: "100%"
    };

    let onMarkerDragEnd = (coord, index, markers) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        markers[index] = { lat, lng };
        setMarkers(markers);
    }

    let myMarkers = markers && Object.entries(markers).map(([key, val]) => (
        <Marker key={key} id={key} position={{
            lat: val.lat,
            lng: val.lng
        }}
            draggable={true}
            onDragend={(t, map, coord) => onMarkerDragEnd(coord, key, markers)}
        />
    ))


    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setMarkers([
                    { lat: position.coords.latitude, lng: position.coords.longitude }
                ])
            });
        }
    }, [])

    return (
        <>
            <Modal
                className='location-picker-modal'
                show={showLocationPickerModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="header d-flex w-100 align-items-center justify-content-between p-3  border-bottom">
                        <h6 className='p-0 m-0'>Select Your Location</h6>
                        <div onClick={() => setShowLocationPickerModal(false)} className="d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    </div>

                    <Form encType='multipart/form-data' onSubmit={handleSubmit(onSubmit)}>
                        <Map
                            google={props.google}
                            zoom={14}
                            style={mapStyles}
                            initialCenter={
                                {
                                    lat: markers[0].lat,
                                    lng: markers[0].lng
                                }
                            }
                        >
                            {myMarkers}
                        </Map>
                    </Form>
                </Modal.Body>
            </Modal>

            <div className='team-profile-form p-4'>
                <h6 className="border-bottom pb-3 mb-4">Manage Team</h6>
                <Form encType='multipart/form-data' onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="img-upload">
                        <div className="overlay"></div>
                        <img src={preview ? preview : Assets.BrandIcon} alt="BrandIcon" />
                        <input
                            name="image"
                            id="img-upload"
                            type="file"
                            className=""
                            onChange={(e) => handleChange(e)}
                        />
                        <CameraIcon />
                    </label>

                    <InputGroup className='mt-3'>
                        <Form.Control
                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                            name="name"
                            type="text"
                            placeholder="Your Name"
                            {...register("name",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    },
                                })
                            }
                        />
                    </InputGroup>
                    {errors.name && <small className='text-red'>{errors.name.message}</small>}

                    <InputGroup className='mt-3'>
                        <Form.Control
                            onKeyDown={(e) => (e.key === "." || e.key === "e" || e.key === "E") && e.preventDefault()}
                            name="phone"
                            type="number"
                            placeholder="Contact Number"
                            {...register("phone",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PHONE_MIN,
                                        message: VALIDATIONS_TEXT.PHONE_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PHONE_MAX,
                                        message: VALIDATIONS_TEXT.PHONE_MAX
                                    },
                                    pattern: {
                                        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                        message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                    },
                                })
                            }
                        />
                    </InputGroup>
                    {errors.phone && <small className='text-red'>{errors.phone.message}</small>}

                    <InputGroup className='mt-3'>
                        <Form.Control
                            onClick={() => setShowLocationPickerModal(true)}
                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                            name="location"
                            type="text"
                            placeholder="Your Location"
                            {...register("location",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.LOCATION_MAX,
                                        message: VALIDATIONS_TEXT.LOCATION_MAX
                                    },
                                })
                            }
                        />
                    </InputGroup>
                    {errors.location && <small className='text-red'>{errors.location.message}</small>}

                    <InputGroup className='mt-3'>
                        <Form.Control
                            name="email"
                            type="email"
                            placeholder="Email Address"
                            {...register("email",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                    }
                                })
                            }
                        />
                    </InputGroup>
                    {errors.email && <small className='text-red'>{errors.email.message}</small>}

                    <div className='text-center'>
                        <Button type="submit" className="w-100 mt-5 btn-change-password">UPDATE CHANGES</Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyANqpbqOxMaZuvmhp7BE_8Y2om5fghSDQw"
})(TeamProfileForm);