import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Assets from "../../constants/images";
import {Logo} from "../../constants/svgs";

const HalfNavigationLayout = ({ heading, children }) => {
    return (
        <section className="account-setup-layout">
            <div className='half-navigation-layout p-4'>
                <header className='header d-flex align-items-center py-2 px-4'>
                    <Logo/>
                    <div className='w-100 text-end text-sm-center'>
                        <h5 className='text-golden mt-2'>{heading}</h5>
                    </div>
                </header>
                {children}
            </div>
        </section>
    )
}

export default HalfNavigationLayout