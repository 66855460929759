import React,{useContext} from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { InstagramLogo, FacebookLogo, TwitterLogo, LinkedinLogo, YoutubeLogo } from "../../constants/svgs"
import {SelectedPlanData} from "../../App"

const ChoosePlanCard = ({ data }) => {
  const [selectedPlan, setSelectedPlan] = useContext(SelectedPlanData);

  const handleSelectedPlan = (data)=>{
    setSelectedPlan(data)
  }
  
  return (
    <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mb-4">
      <div className='choose-plan-card p-4'>
        <div className='text-center'>
          <h3>{data.price}</h3>
          <div className="description-container">
            <p>{data.description}</p>
          </div>
          <h4>{data.title}</h4>
        </div>

        <div className="icon-div text-center mt-4">
          <ul>
            <li className='me-2 me-sm-3'><NavLink to="#"><InstagramLogo /></NavLink></li>
            <li className='me-2 me-sm-3'><NavLink to="#"><FacebookLogo /></NavLink></li>
            <li className='me-2 me-sm-3'><NavLink to="#"><TwitterLogo /></NavLink></li>
            <li className='me-2 me-sm-3'><NavLink to="#"><LinkedinLogo /></NavLink></li>
            <li className='me-2 me-sm-3'><NavLink to="#"><YoutubeLogo /></NavLink></li>
          </ul>
        </div>

        <div className='mt-4 list-container'>
          <ul>
            {
              data.list.map((list_item, i) => {
                return (
                  <li className="mb-3" key={i}>
                    {list_item}
                  </li>
                )
              })
            }
          </ul>
        </div>

        <div className='text-center my-4'>
          <Row className="justify-content-center">
            <Col xs={10}>
              <NavLink to="/payment-method" onClick={()=>handleSelectedPlan(data)}><Button className="btn-select w-100">SELECT</Button></NavLink>
            </Col>
          </Row>
        </div>
      </div >
    </Col >
  )
}

export default ChoosePlanCard