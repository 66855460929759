import React from 'react'
import { Button } from 'react-bootstrap'
import Assets from '../../constants/images'
import { RiDeleteBin6Line } from "react-icons/ri"
import {success, error} from "../../constants/msg";

const TeamAddedCard = () => {
    return (
        <div className='team-added-card mt-3'>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center ps-2">
                    <img src={Assets.AvatarLarge1} alt="Avatar" />
                    <h6 className='m-0 p-0 ms-3'>Emily Rose</h6>
                </div>

                <div className='btn-delete-container me-2'>
                    <RiDeleteBin6Line className='icon' onClick={()=> success("Collaborator Deleted")} />
                </div>
            </div>
        </div>
    )
}

export default TeamAddedCard