import React, { useState } from 'react'
import { Form, Button, InputGroup } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";

const ChangePasswordForm = () => {
    const navigate = useNavigate();
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const password = watch("newPassword");

    const onSubmit = (data) => {
        success("Password has been updated")
        console.log(data)
    }

    return (
        <div className='change-password-form p-4'>
            <h6 className="border-bottom pb-3 mb-4">Update Your Password</h6>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup className='mt-3'>
                    <Form.Control
                        name="currentPassword"
                        className="password-input"
                        placeholder="Current Password *"
                        type={!showCurrentPassword ? "password" : "text"}
                        {...register("currentPassword",
                            {
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                },
                            })
                        }

                    />
                    <InputGroup.Text>
                        {
                            !showCurrentPassword ?
                                <AiOutlineEye className="eye-icon" onClick={() => setShowCurrentPassword(!showCurrentPassword)} /> :
                                <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowCurrentPassword(!showCurrentPassword)} />
                        }
                    </InputGroup.Text>
                </InputGroup>
                {errors.currentPassword && <small className='text-red'>{errors.currentPassword.message}</small>}

                <InputGroup className='mt-3'>
                    <Form.Control
                        name="newPassword"
                        className="password-input"
                        placeholder="New Password *"
                        type={!showNewPassword ? "password" : "text"}
                        {...register("newPassword",
                            {
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                },
                            })
                        }

                    />
                    <InputGroup.Text>
                        {
                            !showNewPassword ?
                                <AiOutlineEye className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)} /> :
                                <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)} />
                        }
                    </InputGroup.Text>
                </InputGroup>
                {errors.newPassword && <small className='text-red'>{errors.newPassword.message}</small>}

                <InputGroup className='mt-3'>
                    <Form.Control
                        name="confirmPassword"
                        className="password-input"
                        placeholder="Confirm Password *"
                        type={!showConfirmPassword ? "password" : "text"}
                        {...register("confirmPassword",
                            {
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED
                                },
                                validate: (value) =>
                                    value === password || "The Passwords do not match"
                            })
                        }

                    />
                    <InputGroup.Text>
                        {
                            !showConfirmPassword ?
                                <AiOutlineEye className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> :
                                <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                        }
                    </InputGroup.Text>
                </InputGroup>
                {errors.confirmPassword && <small className='text-red'>{errors.confirmPassword.message}</small>}


                <div className='text-center'>
                    <Button type="submit" className="w-100 mt-5 btn-change-password">CHANGE PASSWORD</Button>
                </div>
            </Form>
        </div>
    )
}

export default ChangePasswordForm