import React, { useContext } from 'react'
import { Button, Carousel, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { NavLink, useNavigate } from 'react-router-dom'
import Assets from '../../constants/images'
import { DoneIcon, EditProfileIcon, FireIcon, StarIcon, Badge1, Badge2 } from '../../constants/svgs'
import { ProfilePopupContext } from '../../App'

const ProfilePopup = () => {
    const [handleProfilePopup, setHandleProfilePopup] = useContext(ProfilePopupContext);
    const navigate = useNavigate();

    return (
        <div>
            <Modal
                className='profile-modal'
                show={true}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                        <h6 className='p-0 m-0'>My Profile</h6>
                        <div onClick={() => setHandleProfilePopup(false)} className="d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    </div>

                    <div className="profile-container">
                        <div className="d-flex justify-content-end align-items-center">
                            <EditProfileIcon />
                            <NavLink className="ms-2" to="/edit-profile">Edit Profile</NavLink>
                        </div>

                        <div className='avatar-container d-flex align-items-center mb-3'>
                            <img src={Assets.ProfilePicture} alt="Avatar" />
                            <div>
                                <div className="ms-3">
                                    <h6 className='d-block p-0 m-0'>Mia Valentino</h6>
                                    <small>
                                        miavalentino@gmail.com <br />
                                        +1265946548
                                    </small>
                                </div>
                                <div className='badge-div mt-2'>
                                    <Badge1 />
                                    <Badge2 />
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom pb-4">
                            <p>
                                This is dummy copy. It is not meant to be read.
                                It has been placed here solely to demonstrate
                                the look and feel of finished, typeset text.
                            </p>
                            <Button onClick={()=>navigate("/view-submissions")} className="w-100 btn-submission">View Submissions</Button>
                        </div>

                        <div className='my-4 d-flex align-items-center'>
                            <img src={Assets.BrandIcon} alt="BrandIcon" />
                            <div className="ms-3">
                                <h6>Koto Brand.co</h6>
                                <div >
                                    <StarIcon />
                                    <small className='ms-2'>215 pt</small>
                                </div>
                            </div>
                        </div>

                        <div className="profile-popup-footer d-flex align-items-center mb-3">
                            <div className='me-5 ms-4'>
                                <DoneIcon />
                                <small className='ms-2'>1205 Success Posts</small>
                            </div>

                            <div className='margin-top ms-5'>
                                <FireIcon />
                                <small className='ms-2'>1500</small>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProfilePopup