import React from 'react'
import { InstagramIcon, FacebookIcon, TwitterIcon, LinkedinIcon, YoutubeIcon, } from "../../constants/svgs";
import { Row, Col, Button } from "react-bootstrap";
import Assets from '../../constants/images';
import { NavLink, useNavigate } from 'react-router-dom';

const SocialAccountForm = () => {
  const navigate = useNavigate();
  return (
    <div className='social-account-form mt-4'>
      <h6>Add Your Social Media Accounts</h6>
      <Row>
        <Col xs={12} md={12} lg={12} xl={7} className="mb-4">
          <Row className='mt-3'>
            <Col xs={6} xl={6} lg={6} md={12} className="mb-4 col-full-width">
              <div className='social-account-card px-4 py-3 bg-facebook'>
                <div className='d-flex align-items-center'>
                  <div className='circle-div d-flex align-items-center justify-content-center text-center'>
                    <FacebookIcon />
                  </div>
                  <h6 className='p-0 m-0 ms-4'>Facebook</h6>
                </div>
              </div>
            </Col>

            <Col xs={6} xl={6} lg={6} md={12} className="mb-4 col-full-width">
              <div className='social-account-card px-4 py-3 bg-twitter'>
                <div className='d-flex align-items-center'>
                  <div className='circle-div d-flex align-items-center justify-content-center text-center'>
                    <TwitterIcon />
                  </div>
                  <h6 className='p-0 m-0 ms-4'>Twitter</h6>
                </div>
              </div>
            </Col>

            <Col xs={6} xl={6} lg={6} md={12} className="mb-4 col-full-width">
              <div className='social-account-card px-4 py-3 bg-instagram'>
                <div className='d-flex align-items-center'>
                  <div className='circle-div d-flex align-items-center justify-content-center text-center'>
                    <InstagramIcon />
                  </div>
                  <h6 className='p-0 m-0 ms-4'>Instagram</h6>
                </div>
              </div>
            </Col>

            <Col xs={6} xl={6} lg={6} md={12} className="mb-4 col-full-width">
              <div className='social-account-card px-4 py-3 bg-linkedin'>
                <div className='d-flex align-items-center'>
                  <div className='circle-div linkedin-circle d-flex align-items-center justify-content-center text-center'>
                    <LinkedinIcon />
                  </div>
                  <h6 className='p-0 m-0 ms-4 text-dark'>Linkedin</h6>
                </div>
              </div>
            </Col>

            <Col xs={6} xl={6} lg={6} md={12} className="mb-4 col-full-width">
              <div className='social-account-card px-4 py-3 bg-youtube'>
                <div className='d-flex align-items-center'>
                  <div className='circle-div youtube-circle d-flex align-items-center justify-content-center text-center'>
                    <YoutubeIcon />
                  </div>
                  <h6 className='p-0 m-0 ms-4 text-dark'>Youtube</h6>
                </div>
              </div>
            </Col>

            <Col xs={12}></Col>

            <Col xs={12} sm={6}>
              <Button className="w-100 btn-continue mt-4" onClick={() => navigate('/dashboard')}>CONTINUE</Button>
            </Col>

            <Col xs={12} className="mt-5">
              <NavLink to="/dashboard">Set Up Later</NavLink>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={12} lg={12} xl={5}>
          <div className='img-div mt-3 d-none d-xl-block'>
            <img className='w-100' src={Assets.SocialAccountImage} alt="SocialAccountImage" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SocialAccountForm