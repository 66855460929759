import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ChangePasswordForm from '../components/account-setup/ChangePasswordForm'
import SettingNavigation from '../components/dashboard/SettingNavigation'

const ChangePasswordPage = () => {
    return (
        <div className='change-password-page pb-3'>
            <Row>
                <Col xs={12} className="d-block d-md-none mt-4">
                    <h5 className='text-yellow'>SETTINGS</h5>
                </Col>

                <SettingNavigation />

                <Col xs={12} md={8} lg={6} xl={5} xxl={5} className="mt-4">
                    <ChangePasswordForm />
                </Col>
            </Row>
        </div>
    )
}

export default ChangePasswordPage