import React from 'react'
import { Col, Button, InputGroup, Form } from "react-bootstrap"
import { VALIDATIONS, VALIDATIONS_TEXT, SPECIAL_CHARACTER_ARRAY } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

const InviteTeamCard = ({ heading, buttonText }) => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = (data) => {
        console.log(data)
        navigate("/create-team-post-calendar")
    }

    return (
        <div className="invite-team-card p-4">
            <h6 className='border-bottom pb-4 mb-3'>{heading}</h6>
            <p>
                Invite team members best suited to submit content -
                collaborators will be able to use the mobile app (only)
                to snap and submit content at lightning speed!
            </p>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup className='mt-4'>
                    <Form.Control
                        onKeyDown={(e) => (e.key === "." || e.key === "e" || e.key === "E") && e.preventDefault()}
                        name="phone"
                        type="number"
                        placeholder="Phone Number"
                        {...register("phone",
                            {
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.PHONE_REQUIRED
                                },
                                minLength: {
                                    value: VALIDATIONS.PHONE_MIN,
                                    message: VALIDATIONS_TEXT.PHONE_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PHONE_MAX,
                                    message: VALIDATIONS_TEXT.PHONE_MAX
                                },
                                pattern: {
                                    value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                    message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                },
                            })
                        }
                    />
                </InputGroup>
                {errors.phone && <small className='text-red'>{errors.phone.message}</small>}
                <Button type='submit' className="w-100 mt-3 btn-invite">{buttonText}</Button>
            </Form>
        </div>
    )
}

export default InviteTeamCard