import React from 'react'
import Assets from '../../constants/images'

const NotificationCard = ({status}) => {
    return (
        <div className='notification-card mb-2 border-bottom pb-2'>
            <div className={`notification-box ${status==true && "no-bg"} d-flex align-items-center p-3`}>
                <div>
                    <img src={Assets.AvatarLarge1} alt="Avatar" />
                </div>

                <div className='ms-3'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='d-block'>Ashley Edward</span>
                        <small className='message'>Sent you a message</small>
                    </div>
                    <p className='p-0 m-0'>Especially you and have to go for.... </p>
                    <small className='time-small'>23m ago</small>
                </div>
            </div>
        </div>
    )
}

export default NotificationCard