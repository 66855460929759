import React, { useContext } from 'react'
import { Button, Carousel, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import Assets from '../../constants/images'
import { AddUserIcon, DoneIconSquare, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, RejectIconSquare, TwitterIcon, YoutubeIcon, YoutubeIconWithoutBg } from '../../constants/svgs'
import PendingSubmissionCard from './PendingSubmissionCard'
import { PendingSubmissionPopupContext, CreatePostPopupContext} from '../../App';

const PendingSubmissionPopup = () => {
    const [handlePendingSubmissionPopup, setHandlePendingSubmissionPopup] = useContext(PendingSubmissionPopupContext)
    const [handleCreatePostPopup, setHandleCreatePostPopup] = useContext(CreatePostPopupContext);

    return (
        <div>
            <Modal
                className='pending-submission-modal'
                show={handlePendingSubmissionPopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                        <h6 className='p-0 m-0'>Pending Submissions</h6>
                        <div onClick={() => setHandlePendingSubmissionPopup(false)} className="d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    </div>

                    <div>
                        <PendingSubmissionCard />
                        <PendingSubmissionCard />
                        <PendingSubmissionCard />
                    </div>

                    <div className='text-center mt-4 mb-2'>
                        <Button className="w-100 btn-create-post" onClick={()=>setHandleCreatePostPopup(true)}>CREATE NEW POST </Button>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PendingSubmissionPopup