import React from 'react'
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap'
import Assets from '../constants/images'
import { DoneIcon, FireIcon, StarIcon } from '../constants/svgs'
import LeaderboardCard from '../components/dashboard/LeaderboardCard'

const LeaderboardPage = () => {
    return (
        <div className='leaderboard-page'>
            <Row className="mt-4">
                <Col xs={12} className="mb-4">
                    <h5 className='text-yellow d-block d-md-none'>LEADERBOARD</h5>
                    <h6>Add and Remove Your Social Media Accounts</h6>
                </Col>

                <Col xl={4} lg={4} className="mb-4">
                    <div className="left-container p-4">
                        <img className='w-100' src={Assets.BrandIconRectangle} alt="BrandIcon" />
                        <div className='mt-4'>
                            <p>KOTO Brand.co</p>
                            <div className='mb-2'>
                                <DoneIcon />
                                <h6 className='d-inline-block ms-2'>1205 Success Posts</h6>
                            </div>

                            <div>
                                <FireIcon />
                                <h6 className='d-inline-block ms-2'>1500</h6>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xl={6} lg={6}  className="mb-4 mb-lg-0">
                    <div className="right-container">
                        <div className="">
                            <div className='d-block leaderboard-tabs'>
                                <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" className="py-2 pe-4">
                                    <Tab eventKey="all" title="All">
                                        <div className="leaderboard-card-container">
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="weekly" title="Weekly">
                                        <div className="leaderboard-card-container">
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="monthly" title="Monthly">
                                        <div className="leaderboard-card-container">
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                            <LeaderboardCard />
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default LeaderboardPage