const Assets = {
    Logo: require("../assets/img/logo.png"),
    LogoBlack: require("../assets/img/logo-black.png"),
    Cartoon: require("../assets/img/cartoon.png"),
    CartoonNew: require("../assets/img/cartoon2.png"),
    SocialAccountImage: require("../assets/img/social-account.png"),
    Avatar: require("../assets/img/avatar.png"),
    AvatarGirl: require("../assets/img/avatar2.png"),
    AvatarLarge1: require("../assets/img/avatar1-large.png"),
    AvatarLarge2: require("../assets/img/avatar2-large.png"),
    AvatarLarge3: require("../assets/img/avatar3-large.png"),
    ProfilePicture: require("../assets/img/my-profile-pic.png"),
    PlaceholderImage: require("../assets/img/placeholder-image.png"),
    PostImage1: require("../assets/img/img1.png"),
    PostImage2: require("../assets/img/img2.png"),
    PostImage3: require("../assets/img/img3.png"),
    PostImageLarge1: require("../assets/img/img1-lg.png"),
    PostImageLarge2: require("../assets/img/img2-lg.png"),
    PostImageLarge3: require("../assets/img/img3-lg.png"),
    SinglePostImage: require("../assets/img/single-post-img.png"),
    BrandIcon: require("../assets/img/brand-icon.png"),
    BrandIconRectangle: require("../assets/img/brand-icon-rectangle.png"),
    Rank1: require("../assets/img/rank1.png"),
    Rank2: require("../assets/img/rank2.png"),
    BarIcon: require("../assets/img/bar-icon.png"),
}

export default Assets;
