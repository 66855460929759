import React, { useContext } from 'react'
import ChoosePlanCard from '../components/plan/ChoosePlanCard'
import { ChoosePlanData } from '../App'
import { Container, Row, Col } from 'react-bootstrap';

const ChoosePlanPage = () => {
  const [choosePlan, setChoosePlan] = useContext(ChoosePlanData);
  return (
    <div className='choose-plan-page mt-4'>
      <Row>
        {
          choosePlan.map((v, i) => {
            return (
              <ChoosePlanCard key={i} data={v} />
            )
          })
        }
      </Row>

      <Row>
        <Col xs={12} className="mb-3 mb-sm-0">
          <p className="mt-3 text-center">Need more manager or collaborator accounts?  Contact us and we’ll find a rate that fits your needs.</p>
        </Col>
      </Row>
    </div>
  )
}

export default ChoosePlanPage