import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FilterSearchPopup from '../components/dashboard/FilterSearchPopup'
import InviteTeamCardDashboard from '../components/dashboard/InviteTeamCardDashboard'
import PendingSubmissionPopup from '../components/dashboard/PendingSubmissionPopup'
import RequestTeamCard from '../components/dashboard/RequestTeamCard'
import TeamAddedCard from '../components/dashboard/TeamAddedCard'
import { ToastContainer } from "react-toastify";

const UserManagementPage = () => {
    return (
        <div className='user-management-page'>
            <ToastContainer />
            <Row className="mt-4">
                <Col xs={12} xl={6} className="mb-4">
                    <h5 className='text-yellow d-block d-md-none'>USER MANAGEMENT</h5>
                    <h6>Joining Team Request</h6>
                    <div className='request-team-card-container mt-4 p-4'>
                        <div className="holder">
                            <RequestTeamCard />
                            <RequestTeamCard />
                            <RequestTeamCard />
                            <RequestTeamCard />
                        </div>
                    </div>
                </Col>

                <Col xs={12} xl={6} className="">
                    <Row>
                        <Col xs={12} lg={6} className="mb-4">
                            <h6 className='mb-4'>Invite Collaborator</h6>
                            <InviteTeamCardDashboard heading="Invite Collaborator" buttonText="Invite Collaborator" />
                        </Col>

                        <Col xs={12} lg={6} className="mb-4">
                            <h6 className='mb-4'>Invite Brand Manager</h6>
                            <InviteTeamCardDashboard heading="Invite Brand Manager" buttonText="Invite Brand Manager" />
                        </Col>

                        <Col xs={12} lg={6} className="mb-4">
                            <div className='team-added-card-container p-4'>
                                <h6 className='border-bottom pb-3'>Collaborator Added</h6>
                                <div className="holder">
                                    <TeamAddedCard />
                                    <TeamAddedCard />
                                    <TeamAddedCard />
                                    <TeamAddedCard />
                                    <TeamAddedCard />
                                    <TeamAddedCard />
                                    <TeamAddedCard />
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={6} className="mb-4">
                            <div className='team-added-card-container p-4'>
                                <h6 className='border-bottom pb-3'>Brand Mananger Added</h6>
                                <div className="holder">
                                    <TeamAddedCard />
                                    <TeamAddedCard />
                                    <TeamAddedCard />
                                    <TeamAddedCard />
                                    <TeamAddedCard />
                                    <TeamAddedCard />
                                    <TeamAddedCard />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default UserManagementPage