import React from 'react'
import { Button } from 'react-bootstrap'
import {success, error} from "../../constants/msg";

const RequestTeamCard = () => {
    return (
        <div className='request-team-card'>
            <div className='d-flex align-items-center justify-content-between flex-wrap pb-3 mb-3 border-bottom'>
                <div className='mt-2 mb-2 pe-2'>
                    <h6 className='p-0 m-0'>John Andrew</h6>
                    <small>
                        johnand2132@gmail.com <br />
                        +1265946548
                    </small>
                </div>

                <div className='pe-2'>
                    <Button className="btns btn-accept me-3" onClick={()=>success("Request Accepted")}>ACCEPT</Button>
                    <Button className="btns btn-reject" onClick={()=>success("Request Rejected")}>REJECT</Button>
                </div>
            </div>
        </div>
    )
}

export default RequestTeamCard