import React from 'react'
import { NavLink } from 'react-router-dom';
import InviteTeamCard from '../account-setup/InviteTeamCard';
import { Row, Col } from 'react-bootstrap'

const InviteTeamForm = () => {
    return (
        <div className='invite-team-form mt-5'>
            <Row>
                <Col xs={12} className="d-none custom-d-block mb-3">
                    <div className="text-end">
                        <NavLink className="text-dark me-3" to="/create-team-post-calendar">Skip To Team Post Calendar</NavLink>
                    </div>
                </Col>

                <Col xs={12} sm={6} md={12} lg={6} xl={5} className="mb-4">
                    <InviteTeamCard heading="Invite Collaborators" buttonText="INVITE COLLABORATOR" />
                </Col>

                <Col xs={12} sm={6} md={12} lg={6} xl={5}>
                    <InviteTeamCard heading="Invite Managers" buttonText="INVITE MANAGER" />
                </Col>
                <NavLink className="mt-5" to="/create-team-post-calendar">Continue</NavLink>
            </Row>
        </div>
    )
}

export default InviteTeamForm