import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const RightPanel = ({ Rightheading, subHeading, route, routeText, children }) => {
    return (
        <div className='right'>
            <div className='py-4 p-md-4'>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className='header d-flex justify-content-between align-items-center p-3'>
                                <div>
                                    <h5 className='m-0 p-0'>{Rightheading}</h5>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <h6 className='m-0 p-0 me-2 sub-heading'>{subHeading}</h6>
                                    <NavLink to={`${route}`}>{routeText}</NavLink>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='justify-content-center'>
                        <Col xs={12} xxl={5} xl={6} lg={8} sm={10}>
                            <div className='py-5'>
                                {children}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default RightPanel