import React, { useContext, useState } from 'react'
import { EyeIcon } from '../../constants/svgs'
import PendingPostPopup from './PendingPostPopup';
import { PendingPostPopupContext } from '../../App';
import { useEffect } from 'react';

const PendingPostCard = ({ data }) => {
    const [visible, setVisible] = useState(false);
    
    return (
        <>
            <div className="pending-post-card mt-4 border-bottom pb-3">
                <PendingPostPopup visible={visible} setVisible={setVisible}/>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='avatar-container d-flex'>
                        <img src={data.avatar} alt="Avatar" />
                        <div className='d-flex flex-column ms-2'>
                            <span>{data.name}</span>
                            <small>{data.time}</small>
                            <p className='p-0 m-0'>{data.post}</p>
                        </div>
                    </div>

                    <div className="eye-icon ps-2" onClick={() => setVisible(true)}>
                        <EyeIcon />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PendingPostCard