import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import SettingNavigation from '../components/dashboard/SettingNavigation'
import Assets from '../constants/images'
import { FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon, YoutubeIcon } from '../constants/svgs'

const SocialAccountsManagementPage = () => {
    return (
        <div className='social-accounts-management-page pb-3'>
            <Row>
                <Col xs={12} className="d-block d-md-none mt-4">
                    <h5 className='text-yellow'>SETTINGS</h5>
                </Col>

                <SettingNavigation />

                <Col xs={12} lg={12} xl={9} className="mt-4">
                    <div className='social-account-form'>
                        <h6>Add Your Social Media Accounts</h6>
                        <Row className='mt-3'>
                            <Col xs={6} xl={4} lg={6} md={12} className="mb-4 col-full-width">
                                <div className='social-account-card px-4 py-3 bg-facebook'>
                                    <div className='d-flex align-items-center'>
                                        <div className='circle-div d-flex align-items-center justify-content-center text-center'>
                                            <FacebookIcon />
                                        </div>
                                        <h6 className='p-0 m-0 ms-4'>Facebook</h6>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={6} xl={4} lg={6} md={12} className="mb-4 col-full-width">
                                <div className='social-account-card px-4 py-3 bg-twitter'>
                                    <div className='d-flex align-items-center'>
                                        <div className='circle-div d-flex align-items-center justify-content-center text-center'>
                                            <TwitterIcon />
                                        </div>
                                        <h6 className='p-0 m-0 ms-4'>Twitter</h6>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={6} xl={4} lg={6} md={12} className="mb-4 col-full-width">
                                <div className='social-account-card px-4 py-3 bg-instagram'>
                                    <div className='d-flex align-items-center'>
                                        <div className='circle-div d-flex align-items-center justify-content-center text-center'>
                                            <InstagramIcon />
                                        </div>
                                        <h6 className='p-0 m-0 ms-4'>Instagram</h6>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={6} xl={4} lg={6} md={12} className="mb-4 col-full-width">
                                <div className='social-account-card px-4 py-3 bg-linkedin'>
                                    <div className='d-flex align-items-center'>
                                        <div className='circle-div linkedin-circle d-flex align-items-center justify-content-center text-center'>
                                            <LinkedinIcon />
                                        </div>
                                        <h6 className='p-0 m-0 ms-4 text-dark'>Linkedin</h6>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={6} xl={4} lg={6} md={12} className="mb-4 col-full-width">
                                <div className='social-account-card px-4 py-3 bg-youtube'>
                                    <div className='d-flex align-items-center'>
                                        <div className='circle-div youtube-circle d-flex align-items-center justify-content-center text-center'>
                                            <YoutubeIcon />
                                        </div>
                                        <h6 className='p-0 m-0 ms-4 text-dark'>Youtube</h6>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={6} xl={4} lg={6} md={12}>
                                <div className='img-div d-none d-xl-block'>
                                    <img className='w-100' src={Assets.SocialAccountImage} alt="SocialAccountImage" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SocialAccountsManagementPage