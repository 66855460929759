import React,{ useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import Assets from '../../constants/images';
import { CameraIcon } from '../../constants/svgs';

const EditProfileForm = () => {
  const navigate = useNavigate();
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState(null);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: "onChange"
  });

  const onSubmit = (data) => {
    console.log(data)
    success("Profile Updated")
  }

  const handleImageUpload = (e) => {
    let img = e.target.files[0]
    let objectUrl = URL.createObjectURL(img)
    setImage(img)
    setPreview(objectUrl)
  }

  useEffect(() => {
    setValue("image", image)
  }, [image, preview])

  return (
    <div className='edit-profile-form pb-4 mt-5'>
      <ToastContainer />
      <Row className='justify-content-center'>
        <Col xs={12} xxl={5} xl={6} lg={8} sm={10}>

          <Form encType='multipart/form-data' onSubmit={handleSubmit(onSubmit)}>
            <div className="text-center">
              <label htmlFor="img-upload">
                <div className="overlay"></div>
                <img src={preview===null ?  Assets.ProfilePicture : preview} alt="BrandIcon" />
                <input
                  name="image"
                  id="img-upload"
                  type="file"
                  accept=".png, .jpeg, .jpg"
                  onChange={(e) => handleImageUpload(e)}
                />
                <CameraIcon />
              </label>
            </div>

            <InputGroup className='mt-3'>
              <Form.Control
                onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                name="name"
                type="text"
                placeholder="Your Name"
                {...register("name",
                  {
                    maxLength: {
                      value: VALIDATIONS.NAME,
                      message: VALIDATIONS_TEXT.NAME_MAX
                    },
                  })
                }
              />
            </InputGroup>
            {errors.name && <small className='text-red'>{errors.name.message}</small>}

            <InputGroup className='mt-3'>
              <Form.Control
                onKeyDown={(e) => (e.key === "." || e.key === "e" || e.key === "E") && e.preventDefault()}
                name="phone"
                type="number"
                placeholder="Contact Number"
                {...register("phone",
                  {
                    minLength: {
                      value: VALIDATIONS.PHONE_MIN,
                      message: VALIDATIONS_TEXT.PHONE_MIN
                    },
                    maxLength: {
                      value: VALIDATIONS.PHONE_MAX,
                      message: VALIDATIONS_TEXT.PHONE_MAX
                    },
                    pattern: {
                      value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                      message: VALIDATIONS_TEXT.INVALID_FORMAT,
                    },
                  })
                }
              />
            </InputGroup>
            {errors.phone && <small className='text-red'>{errors.phone.message}</small>}

            <InputGroup className='mt-3'>
              <Form.Control
                className="py-3"
                as="textarea"
                rows={4}
                name="bio"
                placeholder="Bio"
                {...register("bio",
                  {
                    maxLength: {
                      value: VALIDATIONS.BIO_MAX,
                      message: VALIDATIONS_TEXT.BIO_MAX
                    },
                  })
                }
              />
            </InputGroup>
            {errors.bio && <small className='text-red'>{errors.bio.message}</small>}

            <div className='text-center mt-5'>
              <Button type="submit" className="btn-update-profile w-100">UPDATE PROFILE</Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default EditProfileForm