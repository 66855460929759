import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form";
import { Button, Form, InputGroup, Row, Col } from "react-bootstrap";
import { VALIDATIONS, VALIDATIONS_TEXT, SPECIAL_CHARACTER_ARRAY } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { ProfilePicture } from "../../constants/svgs";
import { useDropzone } from 'react-dropzone';
import Assets from "../../constants/images"


const SetProfileForm = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: "onChange"
    });

    const onSubmit = (data) => {
        console.log(data)
        success("Profile Setup Successfully")
        setTimeout(() => {
            navigate("/invite-team")
        }, 1500)
    }

    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };

    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        maxFiles:1,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = Assets.PlaceholderImage;
                    }}
                />
            </div>
        </div>
    ));

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <Row className="justify-content-center">
            <ToastContainer />
            <Col xs={12} xxl={5} xl={6} lg={8} sm={10} className="mb-5 mb-sm-0">
                <div className="d-flex justify-content-end">
                    <div className='d-inline-block setup-profile-form-tabs py-2 ps-3 pe-3 text-end mt-5'>
                        <Button className={`step-button me-1 ${step === 1 && "bg-dark"}`} onClick={() => setStep(1)}>Set Your Profile</Button>
                        <Button className={`step-button ${step === 2 && "bg-dark"}`} type="button" disabled={!isValid} onClick={() => setStep(2)}>Set Team Profile</Button>
                    </div>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {
                        step === 1 &&
                        <div className='setup-profile-form p-4'>
                            <div className='text-center'>
                                <div className="mb-5 dropzone" {...getRootProps({ className: 'dropzone' })}>
                                    <input className="custom" {...getInputProps()} />
                                    <div className="thumb-container">
                                        {
                                            files.length <= 0 ? <ProfilePicture /> : thumbs
                                        }
                                    </div>
                                </div>
                            </div>

                            <InputGroup className='mt-3'>
                                <Form.Control
                                    onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                                    name="name"
                                    type="text"
                                    placeholder="Your Name *"
                                    {...register("name",
                                        {
                                            maxLength: {
                                                value: VALIDATIONS.NAME,
                                                message: VALIDATIONS_TEXT.NAME_MAX
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.NAME_REQUIRED
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.name && <small className='text-red'>{errors.name.message}</small>}

                            <InputGroup className='mt-3'>
                                <Form.Control
                                    onKeyDown={(e) => (e.key === "." || e.key === "e" || e.key === "E") && e.preventDefault()}
                                    name="phone"
                                    type="number"
                                    placeholder="Contact Number"
                                    {...register("phone",
                                        {
                                            minLength: {
                                                value: VALIDATIONS.PHONE_MIN,
                                                message: VALIDATIONS_TEXT.PHONE_MIN
                                            },
                                            maxLength: {
                                                value: VALIDATIONS.PHONE_MAX,
                                                message: VALIDATIONS_TEXT.PHONE_MAX
                                            },
                                            pattern: {
                                                value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.phone && <small className='text-red'>{errors.phone.message}</small>}

                            <InputGroup className='mt-3'>
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="Email Address *"
                                    {...register("email",
                                        {
                                            maxLength: {
                                                value: VALIDATIONS.EMAIL,
                                                message: VALIDATIONS_TEXT.EMAIL_MAX
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                            }
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.email && <small className='text-red'>{errors.email.message}</small>}

                            <InputGroup className='mt-3'>
                                <Form.Control
                                    className="py-3"
                                    as="textarea"
                                    rows={4}
                                    name="bio"
                                    placeholder="Bio"
                                    {...register("bio",
                                        {
                                            maxLength: {
                                                value: VALIDATIONS.BIO_MAX,
                                                message: VALIDATIONS_TEXT.BIO_MAX
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.bio && <small className='text-red'>{errors.bio.message}</small>}

                            <Button type="button" disabled={!isValid} onClick={() => setStep(2)} className='mt-4 w-100 btn-continue'>CONTINUE</Button>
                        </div>

                    }
                    {
                        step === 2 &&
                        <div className='setup-profile-form p-4 mt-5'>
                            <div className='text-center'>
                                <div className="mb-5 dropzone" {...getRootProps({ className: 'dropzone' })}>
                                    <input className="custom" {...getInputProps()} />
                                    <div className="thumb-container">
                                        {
                                            files.length <= 0 ? <ProfilePicture /> : thumbs
                                        }
                                    </div>
                                </div>
                            </div>

                            <InputGroup className='mt-3'>
                                <Form.Control
                                    onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                                    name="teamTitle"
                                    type="text"
                                    placeholder="Team Title *"
                                    {...register("teamTitle",
                                        {
                                            maxLength: {
                                                value: VALIDATIONS.TEAM_TITLE_MAX,
                                                message: VALIDATIONS_TEXT.TEAM_TITLE_MAX
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.TEAM_TITLE_REQUIRED
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.teamTitle && <small className='text-red'>{errors.teamTitle.message}</small>}

                            <Button type="submit" className='mt-4 w-100 btn-continue'>SAVE AND CONTINUE</Button>
                        </div>
                    }
                </Form>
            </Col>
        </Row>
    )
}

export default SetProfileForm