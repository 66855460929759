import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { TotalPostStatsIcon } from '../../constants/svgs'

const StatsboxCard = ({ theme, icon, count, title }) => {
    return (
        // <Col xs={12} md={6} lg={6} xl={4} className="mb-4">
            <div className={`stats-card theme-${theme} p-3 `}>
                <div className='d-flex flex-column flex-lg-row align-items-lg-center'>
                    <div className='icon-div'>
                        {icon}
                    </div>
                    <div className='ms-lg-3 mt-2 mt-lg-0'>
                        <h2 className='p-0 m-0'>{count}</h2>
                        <h4 className='p-0 m-0'>{title}</h4>
                    </div>
                </div>
            </div>
        // </Col>
    )
}

export default StatsboxCard

