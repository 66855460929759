import React, { useContext, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "./layouts/authLayout/AuthLayout";
import SigninForm from './components/authentication/SigninForm';
import SignupForm from './components/authentication/SignupForm';
import ForgotPasswordForm from './components/authentication/ForgotPasswordForm';
import VerificationCodeForm from './components/authentication/VerificationCodeForm';
import ResetPasswordForm from './components/authentication/ResetPasswordForm';
import HalfNavigationLayout from './layouts/accountSetupLayout/HalfNavigationLayout';
import FullNavigationLayout from './layouts/accountSetupLayout/FullNavigationLayout';
import ChoosePlanPage from './pages/ChoosePlanPage';
import PaymentMethodPage from './pages/PaymentMethodPage';
import SetProfileForm from './components/account-setup/SetProfileForm';
import InviteTeamForm from './components/account-setup/InviteTeamForm';
import CreateTeamPostCalendarForm from './components/account-setup/CreateTeamPostCalendarForm';
import { InfoIcon } from "./constants/svgs";
import SocialAccountForm from './components/account-setup/SocialAccountForm';
import PostManagementCard from './components/dashboard/PostManagementCard';
import SinglePostManagementCard from './components/dashboard/SinglePostManagementCard';
import CreatePostPopup from './components/dashboard/CreatePostPopup';
import DashboardLayout from './layouts/dashboardLayout/DashboardLayout';
import DashboardPage from './pages/DashboardPage';
import PostManagementPage from './pages/PostManagementPage';
import SinglePostManagementPage from './pages/SinglePostManagementPage';
import LeaderboardPage from './pages/LeaderboardPage';
import UserManagementPage from './pages/UserManagementPage';
import EditProfileForm from './components/account-setup/EditProfileForm';
import ReportPage from './pages/ReportPage';
import RewardsManagementPage from './pages/RewardsManagementPage';
import SocialAccountsManagementPage from './pages/SocialAccountsManagementPage';
import SubscriptionManagementPage from './pages/SubscriptionManagementPage';
import AutoPostPage from './pages/AutoPostPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TeamProfilePage from './pages/TeamProfilePage';
import PushNotificationPage from './pages/PushNotificationPage';
import { CreatePostPopupContext, NotificationPopupContext, ProfilePopupContext, FilterPopupContext } from "./App"
import PendingPostPage from './pages/PendingPostPage';
import CalendarPage from './pages/CalendarPage';
import Assets from "./constants/images";
import ProtectedRoute from './ProtectedRoute';
import MySubmissionsPage from './pages/MySubmissionsPage';

const BlankComponent = () => {
    return (
        <></>
    )
}

const Navigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [handleProfilePopup, setHandleProfilePopup] = useContext(ProfilePopupContext);

    useEffect(() => {
        setHandleProfilePopup(false)
        document.body.scrollTop = 0
    }, [navigate, location])

    return (
        <Routes>
            <Route path="/" element={<AuthLayout leftHeading="WELCOME BACK TO RODEO" paragraph="Keeping up on social media is a hassle. That’s Bull. Rodeo makes posting content fun and easy." Rightheading="SIGN IN" subHeading="Don't have an account? " route="/signup" routeText="SIGN-UP" image={Assets.Cartoon} children={<SigninForm />} />}></Route>
            <Route path="/signup" element={<AuthLayout leftHeading="WELCOME TO RODEO" paragraph="Keeping up on social media is a hassle. That’s Bull. Rodeo makes posting content fun and easy." Rightheading="SIGN UP" subHeading="Already have an account? " route="/" routeText="SIGN-IN" image={Assets.CartoonNew} children={<SignupForm />} />}></Route>
            <Route path="/forgot-password" element={<AuthLayout leftHeading="" paragraph="" Rightheading="FORGOT PASSWORD" subHeading="" route="" routeText="" image={Assets.Cartoon} children={<ForgotPasswordForm />} />}></Route>
            <Route path="/verification" element={<AuthLayout leftHeading="" paragraph="" Rightheading="VERIFICATION" subHeading="" route="" routeText="" image={Assets.Cartoon} children={<VerificationCodeForm />} />}></Route>
            <Route path="/reset-password" element={<AuthLayout leftHeading="" paragraph="" Rightheading="RESET PASSWORD" subHeading="" route="" routeText="" image={Assets.Cartoon} children={<ResetPasswordForm />} />}></Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/choose-plan" element={<HalfNavigationLayout heading="CHOOSE YOUR PLAN" children={<ChoosePlanPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/payment-method" element={<HalfNavigationLayout heading="PAYMENT METHOD" children={<PaymentMethodPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/set-profile" element={<FullNavigationLayout islogoShow={true} Icon={BlankComponent} heading="SET PROFILE" subHeading="" link="create-team-post-calendar" children={<SetProfileForm />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/invite-team" element={<FullNavigationLayout islogoShow={false} Icon={BlankComponent} heading="INVITE TEAM" subHeading="Skip To Team Post Calendar" link="create-team-post-calendar" children={<InviteTeamForm />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/create-team-post-calendar" element={<FullNavigationLayout islogoShow={false} Icon={InfoIcon} heading="CREATE TEAM POST CALENDAR" subHeading="Skip To Dashboard" link="dashboard" children={<CreateTeamPostCalendarForm subHeading="Skip To Dashboard" link="dashboard" />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/create-team-post-calendar" element={<FullNavigationLayout islogoShow={false} Icon={InfoIcon} heading="SET YOUR PROFILE STRATEGY" subHeading="Skip To Dashboard" link="dashboard" children={<CreateTeamPostCalendarForm subHeading="Skip To Dashboard" link="dashboard" />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/social-accounts" element={<FullNavigationLayout islogoShow={false} Icon={BlankComponent} heading="SOCIAL ACCOUNTS" subHeading="" children={<SocialAccountForm />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<DashboardLayout Icon={BlankComponent} heading="DASHBOARD - " secondHeading="KOTO BRAND.CO" subHeading="" children={<DashboardPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/post-management" element={<DashboardLayout Icon={InfoIcon} heading="POST MANAGEMENT" secondHeading="" subHeading="" children={<PostManagementPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/view-submissions" element={<DashboardLayout Icon={BlankComponent} heading="MY SUBMISSIONS" secondHeading="" subHeading="" children={<MySubmissionsPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/post-management-calendar" element={<DashboardLayout Icon={BlankComponent} heading="POST MANAGEMENT" secondHeading="" subHeading="" children={<CalendarPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/pending-post" element={<DashboardLayout Icon={BlankComponent} heading="PENDING POST" secondHeading="" subHeading="" children={<PendingPostPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/post-management/:id" element={<DashboardLayout Icon={InfoIcon} heading="POST MANAGEMENT" secondHeading="" subHeading="" children={<SinglePostManagementPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/leaderboard" element={<DashboardLayout Icon={BlankComponent} heading="LEADERBOARD" secondHeading="" subHeading="" children={<LeaderboardPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/user-management" element={<DashboardLayout Icon={BlankComponent} heading="USER MANAGEMENT" secondHeading="" subHeading="" children={<UserManagementPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/edit-profile" element={<DashboardLayout Icon={BlankComponent} heading="EDIT PROFILE" secondHeading="" subHeading="" children={<EditProfileForm />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/report" element={<DashboardLayout Icon={BlankComponent} heading="REPORT" secondHeading="" subHeading="" children={<ReportPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/settings/rewards-management" element={<DashboardLayout Icon={BlankComponent} heading="SETTINGS" secondHeading="" subHeading="" children={<RewardsManagementPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/settings/social-accounts-management" element={<DashboardLayout Icon={BlankComponent} heading="SOCIAL ACCOUNTS MANAGEMENT" secondHeading="" subHeading="" children={<SocialAccountsManagementPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/settings/subscription-management" element={<DashboardLayout Icon={BlankComponent} heading="SUBSCRIPTION MANAGEMENT" secondHeading="" subHeading="" children={<SubscriptionManagementPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/settings/push-notification-management" element={<DashboardLayout Icon={BlankComponent} heading="SETTINGS" secondHeading="" subHeading="" children={<PushNotificationPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/settings/auto-post" element={<DashboardLayout Icon={BlankComponent} heading="SETTINGS" secondHeading="" subHeading="" children={<AutoPostPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/settings/create-team-post-calendar" element={<FullNavigationLayout islogoShow={false} Icon={InfoIcon} heading="SET YOUR PROFILE STRATEGY" subHeading="Back To Setting" link="settings/rewards-management" children={<CreateTeamPostCalendarForm subHeading="Back To Setting" link="settings/rewards-management" />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/settings/change-password" element={<DashboardLayout Icon={BlankComponent} heading="SETTINGS" secondHeading="" subHeading="" children={<ChangePasswordPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/settings/team-profile" element={<DashboardLayout Icon={BlankComponent} heading="SETTINGS" secondHeading="" subHeading="" children={<TeamProfilePage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/settings/terms-and-conditions" element={<DashboardLayout Icon={BlankComponent} heading="SETTINGS" secondHeading="" subHeading="" children={<TermsAndConditionsPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/settings/privacy-policy" element={<DashboardLayout Icon={BlankComponent} heading="SETTINGS" secondHeading="" subHeading="" children={<PrivacyPolicyPage />} />}></Route>
            </Route>
        </Routes>
    )
}

export default Navigation