import React, { useState } from 'react'
import { DoneIcon, PendingIcon, RejectIcon, FacebookIcon, TwitterIcon, InstagramIcon } from '../../constants/svgs'
import Assets from '../../constants/images'
import { Row, Col, Carousel, NavDropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs"

const SinglePostManagementCard = ({ handleClickEditPostPopup }) => {
    return (
        <Col xs={12} sm={12} md={8} lg={6} className="mb-4">
            <div className='post-management-card single-post-management-card p-3 pt-2'>
                <div className='d-flex justify-content-end'>
                    <NavDropdown title={<BsThreeDots className='icon-dots' />} id="basic-nav-dropdown">

                        <NavDropdown.Item>
                            <div className='operation-box'>
                                <div className='w-100'>
                                    <div className="hover-div border-bottom">
                                        <button className='w-100 text-start' onClick={() => handleClickEditPostPopup()}>Edit Post </button>
                                    </div>

                                    <div className="hover-div border-bottom">
                                        <button className='w-100 text-start'>Approve Post</button>
                                    </div>

                                    <div className="hover-div border-bottom">
                                        <button className='w-100 text-start'>Reject Post</button>
                                    </div>

                                    <div className="hover-div">
                                        <button className="w-100 text-start">Delete Post</button>
                                    </div>
                                </div>
                            </div>
                        </NavDropdown.Item>
                    </NavDropdown>
                </div>

                <div className='mb-3'>
                    <div className='d-flex align-items-center'>
                        <DoneIcon />
                        <small className='ms-3'>Schedule for Nov, 18th 2021 at 10:15am</small>
                    </div>
                </div>

                <div className='avatar-container d-flex align-items-center '>
                    <img src={Assets.Avatar} alt="Avatar" />
                    <div className='d-flex flex-column ms-2'>
                        <span>Anthony Paul</span>
                        <small>4h ago</small>
                    </div>
                </div>

                <div className='mt-3'>
                    <p>Winter 21 style forecast: Embroidered Jacketsand silk slips <span>#sale #winter</span></p>

                    <div className='footer d-flex flex-xl-row flex-column align-items-xl-center justify-content-between'>
                        <div className='mt-3 mt-xl-0 icon-div d-flex align-items-center'>
                            <div className='me-2'><FacebookIcon /></div>
                            <div className='me-2'><TwitterIcon /></div>
                            <div className='me-2'><InstagramIcon /></div>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <Carousel>
                            <Carousel.Item>
                                <img className="w-100" src={Assets.SinglePostImage} alt="First slide" />
                            </Carousel.Item>

                            <Carousel.Item>
                                <img className="w-100" src={Assets.SinglePostImage} alt="First slide" />
                            </Carousel.Item>

                            <Carousel.Item>
                                <img className="w-100" src={Assets.SinglePostImage} alt="First slide" />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default SinglePostManagementCard