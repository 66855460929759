import React from 'react'
import { StarIcon } from '../../constants/svgs'

const LeaderboardCard = () => {
    return (
        <div className='leaderboard-card'>
            <div className='px-3'>
                <div className='d-flex justify-content-between align-items-center border-bottom py-4 '>
                    <h6 className='p-0 m-0'>01</h6>
                    <h6 className='p-0 m-0'>Emily Rose</h6>
                    <div className='d-flex align-items-center'>
                        <div className='star-icon-container'>
                            <StarIcon />
                        </div>
                        <h6 className='p-0 m-0 ms-2'>215 Points</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaderboardCard