import React, { useState, useEffect, useContext } from 'react'
import { Route, Navigate, Outlet } from "react-router";

const ProtectedRoute = () => {
    const token = localStorage.getItem("access_token")
    return (
        true ? <Outlet /> : <Navigate to="/" />
    )
}

export default ProtectedRoute