import React, { useState, useEffect } from 'react'
import { Form, Button, InputGroup } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { useDropzone } from 'react-dropzone';
import { AddImageIcon } from '../../constants/svgs';
import TimePicker from 'react-times';
import 'react-times/css/material/default.css';
import 'react-times/css/classic/default.css';

const PushNotificationForm = () => {
    const navigate = useNavigate();
    const [value, onChange] = useState('');
    const [time, setTime] = useState("");
    const [uploadFiles, setUploadFiles] = useState([]);

    const { register, handleSubmit, watch, reset, setValue, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = (data) => {
        console.log(data);
        success("Notification Created")
        reset();
    }

    const onTimeChange = (options) => {
        let time = options.hour + ':' + options.minute;
        setTime(time)
        setValue("recuringTime", time);
    }

    useEffect(() => {
        let date = new Date();
        let hours = date.getHours();
        let minute = date.getMinutes();
        if (minute < 10) {
            minute = '0' + minute
        }
        let time = hours + ':' + minute;
        setTime(time);
        setValue("recuringTime", time);
    }, [])

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: files => {
            setValue("files", files)
            setUploadFiles(files.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
        }
    });

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    return (
        <div className='push-notification-form p-4 '>
            <ToastContainer />
            <h6 className="border-bottom pb-3 mb-4">Create New Notification</h6>
            <Form encType='multipart/form-data' onSubmit={handleSubmit(onSubmit)}>
                <InputGroup className='mt-3'>
                    <Form.Control
                        onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                        name="notificationTitle"
                        type="text"
                        placeholder="Notification Title"
                        {...register("notificationTitle",
                            {
                                maxLength: {
                                    value: VALIDATIONS.NOTIFICATION_TITLE_MAX,
                                    message: VALIDATIONS_TEXT.NOTIFICATION_TITLE_MAX
                                },
                            })
                        }
                    />
                </InputGroup>
                {errors.notificationTitle && <small className='text-red'>{errors.notificationTitle.message}</small>}

                <InputGroup className='mt-3'>
                    <Form.Control
                        className="py-3"
                        as="textarea"
                        rows={4}
                        name="notificationDescription"
                        placeholder="Notification Description"
                        {...register("notificationDescription",
                            {
                                maxLength: {
                                    value: VALIDATIONS.NOTIFICATION_DESCRIPTION_MAX,
                                    message: VALIDATIONS_TEXT.NOTIFICATION_DESCRIPTION_MAX
                                },
                            })
                        }
                    />
                </InputGroup>

                <div className="file-uploader-container my-3 p-2">
                    <div className="holder">
                        <div className="uploader">
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <div>
                                    <AddImageIcon />
                                </div>
                                <input type="file" name="image" {...getInputProps()} />
                                {
                                    uploadFiles.length > 0 ?
                                        <small className='mt-3'>{uploadFiles.length} file uploaded</small>
                                        :
                                        <small className='mt-3'>Drop your badge here, or <span>browse</span></small>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Controller
                    name="recuringDate"
                    control={control}
                    defaultValue={null}
                    render={
                        ({ field }) => (
                            <DatePicker
                                minDate={new Date()}
                                className="date-picker mb-3 w-100"
                                placeholderText="Recuring Date"
                                onChange={(e) => field.onChange(e)}
                                selected={field.value}
                            />
                        )
                    }
                />

                <Controller
                    name="recuringTime"
                    control={control}
                    defaultValue={null}
                    render={
                        ({ field }) => (
                            <TimePicker
                                timeMode="12"
                                theme="classic"
                                onTimeChange={(options) => onTimeChange(options)}
                                className="w-100"
                                time={time}
                            />
                        )
                    }
                />

                <div className='text-center'>
                    <Button type="submit" className="w-100 mt-5 btn-create">CREATE</Button>
                </div>
            </Form>
        </div>
    )
}

export default PushNotificationForm