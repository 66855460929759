import React, { useState } from 'react'
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form";
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import OtpInput from "react-otp-input";
import {success, error} from "../../constants/msg";
import { ToastContainer } from "react-toastify";

const VerificationCodeForm = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: "onChange"
    });

    const handleOtpChange = (otp) => {
        setOtp(otp)
        if(otp.length===0){
            setError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if(otp.length > 0 && otp.length < 5){
            setError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else{
            setError(false)
        }
    }


    const onSubmit = (data) => {
        if(otp.length===0){
            setError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if(otp.length > 0 && otp.length < 5){
            setError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else{
            setError(false)
            success("OTP verified")
            setTimeout(()=>{
                navigate("/reset-password")
            },1500)
        }
    }

    return (
        <section className='verification-code-section mt-5'>
            <ToastContainer/>
            <span className='d-block text-center mb-5'>Check your email. We've sent you the PIN <br /> at jo****@***mail.com</span>
            <Row>
                <Col xs={12} md={12}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <OtpInput
                            className="otp-input"
                            value={otp}
                            onChange={(otp) => handleOtpChange(otp)}
                            numInputs={5}
                            isInputNum={true}
                            placeholder="-----"
                            hasErrored={true}
                            focusStyle="focus"
                        />
                        {error && <small className="text-red ms-1">{errorMessage}</small>}

                        <div className='text-center'>
                            <Button disabled={!isValid} type="submit" className="w-100 mt-5">SUBMIT</Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </section>
    )
}

export default VerificationCodeForm