import React, { useContext } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import { PostManagementData } from '../App'
import PostManagementCard from '../components/dashboard/PostManagementCard';
import { AddIcon, CalendarIcon, SwitchToPostManagementIcon, ToggleIcon } from '../constants/svgs';
import { FilterPopupContext } from '../App'
import FilterSearchPopup from '../components/dashboard/FilterSearchPopup';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { FiStar } from "react-icons/fi"
import { BsStarFill } from "react-icons/bs"
import { PendingSubmissionPopupContext } from '../App';
import PendingSubmissionPopup from "../components/dashboard/PendingSubmissionPopup"

const CalendarPage = () => {
    const [postManagement, setPostManagement] = useContext(PostManagementData);
    const [handleFilterPopup, setHandleFilterPopup] = useContext(FilterPopupContext);
    let time = ["12AM", "1AM", "2AM", "3AM", "4AM", "5AM", "6AM", "7AM", "8AM", "9AM", "10AM", "11AM", "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM"]
    const [handlePendingSubmissionPopup, setHandlePendingSubmissionPopup] = useContext(PendingSubmissionPopupContext)

    return (
        <>
            {handlePendingSubmissionPopup && <PendingSubmissionPopup />}
            {handleFilterPopup && <FilterSearchPopup />}
            <div className="calendar-page mb-4">
                <Row className="mt-4">
                    <Col xs={12} className="d-block d-md-none mb-3">
                        <h5 className='text-yellow'>POST MANAGEMENT</h5>
                    </Col>

                    <Col xs={12} xl={8} className="mb-4">
                        <div className="navigation-container d-flex align-items-center text-center justify-content-end">
                            <NavLink className={({ isActive }) => isActive ? "active me-3" : "me-3"} to='/post-management'>
                                <SwitchToPostManagementIcon />
                            </NavLink>
                            <NavLink className="me-3" to='/post-management-calendar'><CalendarIcon /></NavLink>
                            <Button onClick={() => setHandleFilterPopup(true)} className={`btn-filter ${handleFilterPopup && "active"}`}><ToggleIcon /></Button>
                        </div>

                        <div className='calendar-container p-4 pt-3 pb-4 mt-4'>
                            <FullCalendar
                                className="w-100"
                                plugins={[dayGridPlugin, interactionPlugin,]}
                                initialView="dayGridMonth"
                                weekends={false}
                                headerToolbar={{
                                    right: "prev,next",
                                    left: "title",
                                }}
                                events={[
                                    { title: 'event 1', date: '2019-04-01' },
                                    { title: 'event 2', date: '2019-04-02' }
                                ]}
                            />
                        </div>
                    </Col>

                    <Col xs={12} xl={4} className="mb-4">
                        <div className="time-slot">
                            <h6>Post Slots</h6>
                            <div className="time-slot-container p-4 pt-3 cutom-margin">
                                {/* <FullCalendar
                                    className="w-100"
                                    plugins={[resourceTimeGridPlugin, timeGridPlugin, listPlugin ]}
                                    initialView="timeGridDay"
                                    weekends={false}
                                    headerToolbar={{
                                        right: "prev,next",
                                        left: "title",
                                    }}
                                    events={[
                                        { title: 'event 1', start: '2022-06-08T00:00:00'},
                                    ]}
                                /> */}
                                <ul>
                                    {
                                        time.map((v, i) => {
                                            return (
                                                <li key={i} onClick={() => setHandlePendingSubmissionPopup(true)}>
                                                    <div className='add-event-box d-flex border-bottom mb-3'>
                                                        <small className='mt-3'>{v}</small>
                                                        {
                                                            i == 4 ?
                                                                <div className={`w-100 ms-3 p-3 mb-3 add-event-container bg-green d-flex align-items-center justify-content-between`}>
                                                                    <div>
                                                                        <BsStarFill className='text-white' />
                                                                        <small className='text-white ms-2'>Winter 21 style forecast: Embroidered and silk slips</small>
                                                                    </div>
                                                                </div> :

                                                                <div style={{ cursor: "pointer" }} className={`w-100 ms-3 p-3 mb-3 add-event-container bg-grey d-flex align-items-center justify-content-between`}>
                                                                    <div>
                                                                        <FiStar className='text-grey' />
                                                                        <small className='ms-2'>Empty Slot</small>
                                                                    </div>
                                                                    <AddIcon />
                                                                </div>
                                                        }
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CalendarPage