import React from 'react'
import { FacebookIcon } from '../../constants/svgs'

const SocialStatsCard = ({icon, title, count, id}) => {
    return (
        <div className='social-stats-card mt-4'>
            <div className='border-bottom d-flex align-items-center justify-content-between pb-4'>
                <div className='d-flex align-items-center'>
                    <div id={id} className='icon-container d-flex align-items-center justify-content-center me-3 text-center'>
                        {icon}
                    </div>
                    <h6 className='title p-0 m-0'>{title}</h6>
                </div>
                <h6 className='p-0 m-0'>{count}</h6>
            </div>
        </div>
    )
}

export default SocialStatsCard