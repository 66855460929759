import React from 'react'
import { DoneIcon, PendingIcon, RejectIcon, FacebookIcon, TwitterIcon, InstagramIcon } from '../../constants/svgs'
import Assets from '../../constants/images'
import { Row, Col } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

const PostManagementCard = ({ data }) => {
    return (
        // <Col xs={12} sm={12} md={6} lg={4} className="mb-4">
            <NavLink to={`/post-management/${data.id}`}>
                <div className='post-management-card p-3'>
                    <div className='header d-flex align-items-center mb-2'>
                        {
                            data.status === "done" ?
                                <DoneIcon /> :
                                data.status === "reject" ?
                                    <RejectIcon /> :
                                    <PendingIcon />
                        }
                        <small className={`ms-3 ${data.status === "done"? "text-done": data.status === "reject"? "text-reject":"text-pending" }`}>
                            {
                                data.status === "done" ?
                                    data.dateAndTime :
                                    data.status === "reject" ?
                                        "Rejected" :
                                        "Pending for approval"
                            }
                        </small>
                    </div>

                    <div className='avatar-container d-flex align-items-center '>
                        <img src={Assets.Avatar} alt="Avatar" />
                        <div className='d-flex flex-column ms-2'>
                            <span>{data.name}</span>
                            <small>{data.time}</small>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <p>{data.title} <span>{data.hashtag}</span></p>

                        <div className='footer d-flex flex-xl-row flex-column align-items-xl-center justify-content-between'>
                            <div className='d-flex'>
                                {
                                    data.images.map((v, i) => {
                                        return (
                                            <img key={i} className="me-2" src={v} alt="PostImage1" />
                                        )
                                    })
                                }

                                <div className='cutom-number-div d-flex align-items-center justify-content-center text-center'>
                                    <span>+{data.count}</span>
                                </div>
                            </div>

                            <div className='mt-3 mt-xl-0 icon-div d-flex align-items-center'>
                                <div className='ms-xl-2'><FacebookIcon /></div>
                                <div className='ms-xl-2 mx-2 mx-xl-0'><TwitterIcon /></div>
                                <div className='ms-xl-2'><InstagramIcon /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </NavLink>
        // </Col>
    )
}

export default PostManagementCard