import React from 'react';
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

const AuthLayout = ({leftHeading, paragraph, Rightheading, subHeading, route, routeText, image, children}) => {
    return (
        <section className='auth-layout'>
            <div className='d-flex'>
                <LeftPanel 
                    leftHeading={leftHeading}
                    paragraph={paragraph}
                    image={image}
                />
                
                <RightPanel 
                    Rightheading={Rightheading}
                    subHeading={subHeading}
                    route={route}
                    routeText={routeText}
                    children={children}
                />
            </div>
        </section>
    )
}

export default AuthLayout