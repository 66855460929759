import React, { useState } from 'react'
import { Col, Form } from 'react-bootstrap'

const PaymentMethodTabs = ({isCheck, title, icon, onClick}) => {

    return (
        <Col xs={12} sm={6} lg={6} xl={4} className="mt-4">
            <div className={`payment-method-tabs p-4 d-flex align-items-center justify-content-between ${isCheck && "bg-theme-yellow"}`} onClick={onClick}>
                <div>
                    <span className={`${isCheck && "svg-white"}`}>{icon}</span>
                    <span className={`ms-4 ${isCheck && "text-white"} `}>{title}</span>
                </div>

                <div>
                    <Form.Check readOnly type="radio" checked={isCheck} name="paymentMethod" />
                </div>
            </div>
        </Col>
    )
}

export default PaymentMethodTabs