import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { InstagramLogo, FacebookLogo, TwitterLogo, LinkedinLogo, YoutubeLogo, BankCard, StripeAccount, ApplePay } from "../../constants/svgs"
import { SelectedPlanData } from "../../App"

const YourPlanCard = () => {
    const [selectedPlan, setSelectedPlan] = useContext(SelectedPlanData);

    return (
        <div className="choose-plan-card p-4">
            <h6 className='border-bottom pb-4 mb-5'>Your Plan</h6>

            <div>
                <h3>{selectedPlan.price}</h3>
                <p>{selectedPlan.description}</p>
                <h4>{selectedPlan.title}</h4>
            </div>

            <div className="icon-div mt-4">
                <ul>
                    <li className='me-2 mt-2 me-sm-3'><NavLink to="#"><InstagramLogo /></NavLink></li>
                    <li className='me-2 mt-2 me-sm-3'><NavLink to="#"><FacebookLogo /></NavLink></li>
                    <li className='me-2 mt-2 me-sm-3'><NavLink to="#"><TwitterLogo /></NavLink></li>
                    <li className='me-2 mt-2 me-sm-3'><NavLink to="#"><LinkedinLogo /></NavLink></li>
                    <li className='me-2 mt-2 me-sm-3'><NavLink to="#"><YoutubeLogo /></NavLink></li>
                </ul>
            </div>
        </div>
    )
}

export default YourPlanCard