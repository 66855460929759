import React, { createContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './Navigation';
import Assets from './constants/images';

const ChoosePlanData = createContext();
const PostManagementData = createContext();
const PendingPostData = createContext();
const SelectedPlanData = createContext();

// popup context
const CreatePostPopupContext = createContext();
const NotificationPopupContext = createContext();
const ProfilePopupContext = createContext();
const FilterPopupContext = createContext();
const PendingSubmissionPopupContext = createContext();

const App = () => {

  const [choosePlan, setChoosePlan] = useState([
    {
      price: "Individual:  $70/mo",
      description: "For the solopreneur who’s fed up with the time and effort required to keep up on social media.",
      title: "1 Manager (Web & App) Collaborators not included",
      list: ["Photo, Video, Text, and Link Submissions", "Location & Account Tagging(as available by platform)", "Manage / Edit Submissions before Posting(or AutoPost)", "Schedule Posts or Create Post Calendars", "Streaks & Reminder Notifications", "️View Reporting & Post Engagement"]
    },
    {
      price: "Team:  $250/mo",
      description: "Collaborators can submit content via the iOS app. Turn off auto-post to review submissions before being published.",
      title: "1 Manager  (Web & App) 10 Collaborators (App)",
      list: ["Photo, Video, Text, and Link Submissions", "Location & Account Tagging(as available by platform)", "Manage / Edit Submissions before Posting(or AutoPost)", "Schedule Posts or Create Post Calendars", "Streaks & Reminder Notifications", "️Team Leaderboard", "View Reporting & Post Engagement"]
    },
    {
      price: "Marketing Managers:  $  /mo",
      description: "Collaborators can submit content via the iOS app. Turn off auto-post to review submissions before being published.",
      title: "5 Manager  (Web & App) 10 Collaborators (App)",
      list: ["Photo, Video, Text, and Link Submissions", "Location & Account Tagging(as available by platform)", "Manage / Edit Submissions before Posting(or AutoPost)", "Schedule Posts or Create Post Calendars", "Streaks & Reminder Notifications", "️Team Leaderboard", "️View Reporting & Post Engagement"]
    },
  ])

  const [postManagement, setPostManagement] = useState([
    {
      id: "1",
      name: "Anthony Paul",
      time: "4 hours ago",
      dateAndTime: "Schedule for Nov, 18th 2021 at 10:15am",
      avatar: Assets.Avatar,
      title: "Winter 21 style forecast: Embroidered Jacketsand silk slips",
      hashtag: "#sale #winter",
      images: [Assets.PostImage1, Assets.PostImage2, Assets.PostImage3,],
      count: "2",
      status: "done"
    },
    {
      id: "2",
      name: "Anthony Paul",
      time: "4 hours ago",
      dateAndTime: "Schedule for Nov, 18th 2021 at 10:15am",
      avatar: Assets.Avatar,
      title: "Winter 21 style forecast: Embroidered Jacketsand silk slips",
      hashtag: "#sale #winter",
      images: [Assets.PostImage1, Assets.PostImage2, Assets.PostImage3,],
      count: "2",
      status: "pending"
    },
    {
      id: "3",
      name: "Anthony Paul",
      time: "4 hours ago",
      dateAndTime: "Schedule for Nov, 18th 2021 at 10:15am",
      avatar: Assets.Avatar,
      title: "Winter 21 style forecast: Embroidered Jacketsand silk slips",
      hashtag: "#sale #winter",
      images: [Assets.PostImage1, Assets.PostImage2, Assets.PostImage3,],
      count: "2",
      status: "reject"
    },
    {
      id: "4",
      name: "Anthony Paul",
      time: "4 hours ago",
      dateAndTime: "Schedule for Nov, 18th 2021 at 10:15am",
      avatar: Assets.Avatar,
      title: "Winter 21 style forecast: Embroidered Jacketsand silk slips",
      hashtag: "#sale #winter",
      images: [Assets.PostImage1, Assets.PostImage2, Assets.PostImage3,],
      count: "2",
      status: "pending"
    },
    {
      id: "5",
      name: "Anthony Paul",
      time: "4 hours ago",
      dateAndTime: "Schedule for Nov, 18th 2021 at 10:15am",
      avatar: Assets.Avatar,
      title: "Winter 21 style forecast: Embroidered Jacketsand silk slips",
      hashtag: "#sale #winter",
      images: [Assets.PostImage1, Assets.PostImage2, Assets.PostImage3,],
      count: "2",
      status: "done"
    },
    {
      id: "6",
      name: "Anthony Paul",
      time: "4 hours ago",
      dateAndTime: "Schedule for Nov, 18th 2021 at 10:15am",
      avatar: Assets.Avatar,
      title: "Winter 21 style forecast: Embroidered Jacketsand silk slips",
      hashtag: "#sale #winter",
      images: [Assets.PostImage1, Assets.PostImage2, Assets.PostImage3,],
      count: "2",
      status: "reject"
    },

  ])

  const [pendingPost, setPendingPost] = useState([
    {
      id: "1",
      avatar: Assets.Avatar,
      name: "Anthonu Paul",
      time: "4h ago",
      post: "Winter 21 style forecast: Embroidered Jacketsand silk slips",
      caption: "This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.",
      location: "4761 Broadway, New York",
      people: "James, Kennedy, 3 others",
      image: [Assets.PostImage1, Assets.PostImage2, Assets.PostImage3],
      dateAndTime: "Sat, Jan 22, 2022  -  06:30 pm "
    },
    {
      id: "2",
      avatar: Assets.Avatar,
      name: "Anthonu Paul",
      time: "4h ago",
      post: "Winter 21 style forecast: Embroidered Jacketsand silk slips",
      caption: "This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.",
      location: "4761 Broadway, New York",
      people: "James, Kennedy, 3 others",
      image: [Assets.PostImage1, Assets.PostImage2, Assets.PostImage3],
      dateAndTime: "Sat, Jan 22, 2022  -  06:30 pm "
    },
    {
      id: "3",
      avatar: Assets.Avatar,
      name: "Anthonu Paul",
      time: "4h ago",
      post: "Winter 21 style forecast: Embroidered Jacketsand silk slips",
      caption: "This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.",
      location: "4761 Broadway, New York",
      people: "James, Kennedy, 3 others",
      image: [Assets.PostImage1, Assets.PostImage2, Assets.PostImage3],
      dateAndTime: "Sat, Jan 22, 2022  -  06:30 pm "
    },

  ])

  const [selectedPlan, setSelectedPlan] = useState(
    {
      price: "Individual:  $70/mo",
      description: "For the solopreneur who’s fed up with the time and effort required to keep up on social media.",
      title: "1 Manager (Web & App) Collaborators not included",
      list: ["Photo, Video, Text, and Link Submissions", "Location & Account Tagging(as available by platform)", "Manage / Edit Submissions before Posting(or AutoPost)", "Schedule Posts or Create Post Calendars", "Streaks & Reminder Notifications", "️View Reporting & Post Engagement"]
    }
  )

  const [handleCreatePostPopup, setHandleCreatePostPopup] = useState(false);
  const [handleNotificationPopup, setHandleNotificationPopup] = useState(false);
  const [handleProfilePopup, setHandleProfilePopup] = useState(false);
  const [handleFilterPopup, setHandleFilterPopup] = useState(false);
  const [handlePendingSubmissionPopup, setHandlePendingSubmissionPopup] = useState(false);

  return (
    <ChoosePlanData.Provider value={[choosePlan, setChoosePlan]}>
      <PostManagementData.Provider value={[postManagement, setPostManagement]}>
        <PendingPostData.Provider value={[pendingPost, setPendingPost]}>
          <CreatePostPopupContext.Provider value={[handleCreatePostPopup, setHandleCreatePostPopup]}>
            <NotificationPopupContext.Provider value={[handleNotificationPopup, setHandleNotificationPopup]}>
              <ProfilePopupContext.Provider value={[handleProfilePopup, setHandleProfilePopup]}>
                <FilterPopupContext.Provider value={[handleFilterPopup, setHandleFilterPopup]}>
                  <SelectedPlanData.Provider value={[selectedPlan, setSelectedPlan]}>
                    <PendingSubmissionPopupContext.Provider value={[handlePendingSubmissionPopup, setHandlePendingSubmissionPopup]}>
                      <BrowserRouter>
                        <Navigation />
                      </BrowserRouter>
                    </PendingSubmissionPopupContext.Provider>
                  </SelectedPlanData.Provider>
                </FilterPopupContext.Provider>
              </ProfilePopupContext.Provider>
            </NotificationPopupContext.Provider>
          </CreatePostPopupContext.Provider>
        </PendingPostData.Provider>
      </PostManagementData.Provider>
    </ChoosePlanData.Provider>
  )
}

export default App
export { ChoosePlanData, PostManagementData, PendingPostData, SelectedPlanData, CreatePostPopupContext, NotificationPopupContext, ProfilePopupContext, FilterPopupContext, PendingSubmissionPopupContext }
