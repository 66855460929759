import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Assets from '../../constants/images';

const LeftPanel = ({ leftHeading, paragraph, image }) => {
    return (
        <div className='left d-none d-md-block'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className='py-5 px-4'>
                            <img src={Assets.Logo} alt="Logo" />
                            <div className='pt-5 pb-4'>
                                <h1 className='mb-4'>{leftHeading}</h1>
                                <p>{paragraph}</p>
                            </div>
                            <div className='text-center'>
                                <img className='w-100 cartoon-img' src={image} alt="Cartoon" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default LeftPanel