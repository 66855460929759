import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import SettingNavigation from '../components/dashboard/SettingNavigation'
import Assets from '../constants/images'
import { Badge1Large, Badge1XLarge, Badge2Large, Badge3Large, Badge4Large, Badge5Large, DoneIcon, FireIcon } from '../constants/svgs'
import { RiArrowDropDownFill } from "react-icons/ri"

const RewardsManagementPage = () => {
    const [tabs, setTabs] = useState("1");
    const [showMore, setShowMore] = useState(false);

    return (
        <div className='rewards-management-page pb-3'>
            <Row>
                <Col xs={12} className="d-block d-md-none mt-4">
                    <h5 className='text-yellow'>SETTINGS</h5>
                </Col>

                <SettingNavigation />

                <Col xs={12} lg={12} xl={9} className="mt-4">
                    <Row>
                        <Col xs={12} lg={8} className="mb-4">
                            <div className="badge-list-container p-4">
                                <h6 className='pb-3 border-bottom mb-4'>Recent Added Badges</h6>
                                <div className='text-center'>
                                    <Row>
                                        <Col xs={6} lg={3} xl={2} className="mb-3">
                                            <div className="cursor-div" onClick={() => setTabs("1")}>
                                                <Badge1Large />
                                                <small className={tabs === "1" && "text-yellow"}>Badge Title 1</small>
                                            </div>
                                        </Col>

                                        <Col xs={6} lg={3} xl={2} className="mb-3">
                                            <div className="cursor-div" onClick={() => setTabs("2")}>
                                                <Badge2Large />
                                                <small className={tabs === "2" && "text-yellow"}>Badge Title 2</small>
                                            </div>
                                        </Col>

                                        <Col xs={6} lg={3} xl={2} className="mb-3">
                                            <div className="cursor-div" onClick={() => setTabs("3")}>
                                                <Badge3Large />
                                                <small className={tabs === "3" && "text-yellow"}>Badge Title 3</small>
                                            </div>
                                        </Col>

                                        <Col xs={6} lg={3} xl={2} className="mb-3">
                                            <div className="cursor-div" onClick={() => setTabs("4")}>
                                                <Badge4Large />
                                                <small className={tabs === "4" && "text-yellow"}>Badge Title 4</small>
                                            </div>
                                        </Col>

                                        <Col xs={6} lg={3} xl={2} className="mb-3">
                                            <div className="cursor-div" onClick={() => setTabs("5")}>
                                                <Badge5Large />
                                                <small className={tabs === "5" && "text-yellow"}>Badge Title 5</small>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className=' mt-4'>
                                <div className="badge-description-container p-4">
                                    {
                                        (tabs === "1" || tabs === "2" || tabs === "3" || tabs === "4" || tabs === "5") &&
                                        <div className='mt-4'>
                                            <Row className="justify-content-center">
                                                <Col xs={5} md={3} className="mb-3">
                                                    <Badge1XLarge />
                                                </Col>

                                                <Col xs={12} md={9}>
                                                    <h6 className='title'>Badge Title 1</h6>
                                                    <p>
                                                        This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.
                                                        <br /> <br />
                                                        These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance devoid of content yet rich in form.
                                                    </p>

                                                    <Row className="mt-4">
                                                        <Col xs={12} sm={6} className="mb-4">
                                                            <div className='d-flex align-items-center'>
                                                                <img src={Assets.Avatar} alt="Avatar" />
                                                                <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                            </div>
                                                        </Col>

                                                        <Col xs={12} sm={6} className="mb-4">
                                                            <div className='d-flex align-items-center'>
                                                                <img src={Assets.Avatar} alt="Avatar" />
                                                                <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                            </div>
                                                        </Col>

                                                        <Col xs={12} sm={6} className="mb-4">
                                                            <div className='d-flex align-items-center'>
                                                                <img src={Assets.Avatar} alt="Avatar" />
                                                                <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                            </div>
                                                        </Col>

                                                        <Col xs={12} sm={6} className="mb-4">
                                                            <div className='d-flex align-items-center'>
                                                                <img src={Assets.Avatar} alt="Avatar" />
                                                                <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    }

                                    {
                                        showMore &&
                                        <div className='my-4'>
                                            <Row className="justify-content-center">
                                                <Col xs={5} md={3} className="mb-3">
                                                    <Badge1XLarge />
                                                </Col>

                                                <Col xs={12} md={9}>
                                                    <h6 className='title'>Badge Title 1</h6>
                                                    <p>
                                                        This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.
                                                        <br /> <br />
                                                        These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance devoid of content yet rich in form.
                                                    </p>

                                                    <Row className="mt-4">
                                                        <Col xs={12} sm={6} className="mb-4">
                                                            <div className='d-flex align-items-center'>
                                                                <img src={Assets.Avatar} alt="Avatar" />
                                                                <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                            </div>
                                                        </Col>

                                                        <Col xs={12} sm={6} className="mt-4">
                                                            <div className='d-flex align-items-center'>
                                                                <img src={Assets.Avatar} alt="Avatar" />
                                                                <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                            </div>
                                                        </Col>

                                                        <Col xs={12} sm={6} className="mt-4">
                                                            <div className='d-flex align-items-center'>
                                                                <img src={Assets.Avatar} alt="Avatar" />
                                                                <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                            </div>
                                                        </Col>

                                                        <Col xs={12} sm={6} className="mt-4">
                                                            <div className='d-flex align-items-center'>
                                                                <img src={Assets.Avatar} alt="Avatar" />
                                                                <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    <div>
                                        <Row className="justify-content-center">
                                            <Col xs={12} md={{ offset: 3, span: 9 }}>
                                                <div>
                                                    <NavLink className="custom-dropdown" to="#" onClick={() => setShowMore(!showMore)}>3 more</NavLink>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={4} className="mb-4">
                            <div className="left-container p-4">
                                <img className='w-100' src={Assets.BrandIconRectangle} alt="BrandIcon" />
                                <div className='mt-4'>
                                    <p>KOTO Brand.co</p>
                                    <div>
                                        <FireIcon />
                                        <h6 className='d-inline-block ms-2'>1500</h6>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default RewardsManagementPage