import React, { useState, useContext } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import SettingNavigation from '../components/dashboard/SettingNavigation'
import YourPlanCard from '../components/plan/YourPlanCard'

const SubscriptionManagementPage = () => {
    return (
        <div className='subscription-management-page pb-3'>
            <Row>
                <Col xs={12} className="d-block d-md-none mt-4">
                    <h5 className='text-yellow'>SETTINGS</h5>
                </Col>

                <SettingNavigation />

                <Col xs={12} md={8} lg={6} xl={5} xxl={4} className="mt-4">
                    <YourPlanCard />
                    <NavLink className="d-block mt-5" to="/choose-plan">Change Plan</NavLink>
                </Col>
            </Row>
        </div>
    )
}

export default SubscriptionManagementPage