import React, { useContext, useState } from 'react'
import { Row } from 'react-bootstrap'
import EditPostPopup from '../components/dashboard/EditPostPopup';
import SinglePostManagementCard from '../components/dashboard/SinglePostManagementCard';

const SinglePostManagementPage = () => {
    const [handleEditPostPopup, setHandleEditPostPopup] = useState(false);

    const handleClickEditPostPopup = () => {
        setHandleEditPostPopup(!handleEditPostPopup)
    }

    return (
        <>
            {handleEditPostPopup===true && <EditPostPopup handleClickEditPostPopup={handleClickEditPostPopup} /> }
            <div className="post-management-page">
                <Row className="mt-4">
                    <SinglePostManagementCard handleClickEditPostPopup={handleClickEditPostPopup} />
                </Row>
            </div>
        </>
    )
}

export default SinglePostManagementPage