import React, { useContext } from 'react'
import { Button, Carousel, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import Assets from '../../constants/images'
import { AddUserIcon, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, TwitterIcon, YoutubeIcon, YoutubeIconWithoutBg } from '../../constants/svgs'
import NotificationCard from './NotificationCard'
import { NotificationPopupContext } from "../../App";

const NotificationPopup = () => {
    const [handleNotificationPopup, setHandleNotificationPopup] = useContext(NotificationPopupContext);
    return (
        <div>
            <Modal
                className='notification-modal'
                show={true}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"

            >
                <Modal.Body>
                    <div className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                        <h6 className='p-0 m-0'>Notifications <span>(2)</span></h6>
                        <div onClick={() => setHandleNotificationPopup(false)} className="d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    </div>

                    <div className='notification-container'>
                        <NotificationCard status={false} />
                        <NotificationCard status={false} />
                        <NotificationCard status={true} />
                        <NotificationCard status={true} />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default NotificationPopup