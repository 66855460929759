import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Assets from "../../constants/images";
import { DashboardIcon, LeaderboardIcon, LogoutIcon, PostManagementIcon, ReportIcon, SettingIcon, UserManagementIcon } from '../../constants/svgs';
import { BsPlus, BsThreeDotsVertical, BsFillPlusCircleFill } from "react-icons/bs"
import { MdOutlineLibraryBooks } from "react-icons/md"
import { FaBars, FaTimes } from "react-icons/fa"
import { IoMdNotificationsOutline } from "react-icons/io"
import { BiCalendar } from "react-icons/bi"
import CreatePostPopup from "../../components/dashboard/CreatePostPopup"
import NotificationPopup from '../../components/dashboard/NotificationPopup';
import ProfilePopup from '../../components/dashboard/ProfilePopup';
import { FaAngleLeft } from "react-icons/fa";
import { CreatePostPopupContext, NotificationPopupContext, ProfilePopupContext } from "../../App";

const DashboardLayout = ({ Icon, children, heading, secondHeading, subHeading }) => {
    const [handleCreatePostPopup, setHandleCreatePostPopup] = useContext(CreatePostPopupContext);
    const [handleNotificationPopup, setHandleNotificationPopup] = useContext(NotificationPopupContext);
    const [handleProfilePopup, setHandleProfilePopup] = useContext(ProfilePopupContext);
    const [handleSidebar, setHandleSidebar] = useState(true);
    const [handleFullSidebar, setHandleFullSidebar] = useState(false);

    useEffect(() => {
        if (handleFullSidebar === true) {
            document.body.classList.add("body-overflow-hidden")
            document.body.classList.remove("body-overflow-hidden-remove")
        }
        else{
            document.body.classList.remove("body-overflow-hidden")
            document.body.classList.add("body-overflow-hidden-remove")
        }
    }, [handleFullSidebar])


    return (
        <>
            {handleCreatePostPopup === true && <CreatePostPopup />}
            {handleNotificationPopup === true && <NotificationPopup />}
            {handleProfilePopup === true && <ProfilePopup />}

            <section className={`dashboard-layout ${handleSidebar === false && 'remove-active-sidebar'}`}>
                <div className='bg-background p-4 d-flex'>
                    <div className={`left  ${handleFullSidebar === true && 'activeSidebar'}`}>
                        <div className='d-none d-sm-flex navigation-controller' onClick={() => setHandleSidebar(!handleSidebar)}>
                            <FaAngleLeft className='icon' />
                        </div>

                        <Container fluid>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-sm-none d-block text-end mt-2' onClick={() => setHandleFullSidebar(false)}>
                                        <FaTimes className="text-white" />
                                    </div>

                                    <div className='py-sm-5 py-4 text-center'>
                                        <img src={Assets.Logo} alt="Logo" />
                                    </div>
                                </Col>

                                <Col className="col-no-padding" xs={12}>
                                    <div className='overflow-controller'>
                                        <small className='px-4 d-block mb-2'>Main Menu</small>
                                        <ul>
                                            <li>
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active" : "px-4 d-flex align-items-center"} to="/dashboard">
                                                    <DashboardIcon />
                                                    <span className="ms-3">Dashboard</span>
                                                </NavLink>
                                            </li>

                                            <small className='px-4 d-block mt-5 mb-2'>Workspace</small>
                                            <li>
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active" : "px-4 d-flex align-items-center"} to="/post-management">
                                                    <PostManagementIcon />
                                                    <span className="ms-3">Post Management</span>
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active" : "px-4 d-flex align-items-center"} to="/leaderboard">
                                                    <LeaderboardIcon />
                                                    <span className="ms-3">Leaderboard</span>
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active" : "px-4 d-flex align-items-center"} to="/user-management">
                                                    <UserManagementIcon />
                                                    <span className="ms-3">User Management</span>
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active" : "px-4 d-flex align-items-center"} to="/report">
                                                    <ReportIcon />
                                                    <span className="ms-3">Report</span>
                                                </NavLink>
                                            </li>

                                            <small className='px-4 d-block mt-5 mb-2'>General</small>
                                            <li>
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active" : "px-4 d-flex align-items-center"} to="/settings/rewards-management">
                                                    <SettingIcon />
                                                    <span className="ms-3">Settings</span>
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active" : "px-4 d-flex align-items-center"} to="/">
                                                    <LogoutIcon />
                                                    <span className="ms-3">Log Out</span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className='right pb-4'>
                        <div className='ps-md-4'>
                            <Container fluid>
                                <Row className='justify-content-center'>
                                    <Col xs={12}>
                                        <div className='header d-flex justify-content-between align-items-center p-3'>
                                            <div className='icon-container no-bg d-flex d-sm-none' onClick={() => setHandleFullSidebar(!handleFullSidebar)}>
                                                <FaBars />
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <h5 className='m-0 p-0 me-3 d-none d-md-block'>{heading}<em>{secondHeading}</em></h5>
                                                <OverlayTrigger
                                                    key="right"
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip className='text-start'>
                                                            <span style={{ fontSize: "14px" }} className='text-dark'>
                                                                Creating a Post Calendar is critical to maintaining
                                                                a uniform strategy, and it keeps you consistent.
                                                                Every time you create or submit a post, you have the
                                                                option to assign it to a future Post slot.  So, design
                                                                your ideal Post Calendar and make sure your slots are
                                                                filled ahead of time! We suggest starting light, and
                                                                adding slots as desired - that way you're not overwhelmed
                                                                from the start.
                                                            </span>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="d-none d-lg-inline" type="button">{<Icon />}</span>
                                                </OverlayTrigger>
                                            </div>

                                            <div className='d-flex'>
                                                <div className='d-flex d-lg-none me-2 icon-container' onClick={() => setHandleCreatePostPopup(true)}>
                                                    <BsFillPlusCircleFill className='icon-18 text-theme-yellow icon' />
                                                </div>

                                                <Button onClick={() => setHandleCreatePostPopup(true)} className="me-3 btn-create-post d-flex align-items-center d-none d-lg-flex">
                                                    <BsPlus className='icon' />
                                                    <span className='ms-2'>CREATE POST</span>
                                                </Button>

                                                <div className='me-2 icon-container'>
                                                    <NavLink to="/post-management-calendar"><BiCalendar className='icon-18 icon' /></NavLink>
                                                </div>

                                                <div className='me-2 icon-container' onClick={() => setHandleNotificationPopup(true)}>
                                                    <IoMdNotificationsOutline className='icon-18 icon' />
                                                    <span className='notification-icon'></span>
                                                </div>

                                                <div onClick={() => setHandleProfilePopup(true)} className='me-2 avatar-container d-flex align-items-center'>
                                                    <img src={Assets.AvatarGirl} alt="Avatar" />
                                                    <div className='d-lg-flex flex-lg-column ms-2 d-none'>
                                                        <span>Mila V</span>
                                                        <small>Brand Title</small>
                                                    </div>
                                                </div>

                                                {/* <div className='icon-container no-bg d-flex d-sm-none' onClick={() => setHandleFullSidebar(!handleFullSidebar)}>
                                                    <FaBars />
                                                </div> */}
                                                <div className='icon-container no-bg d-none d-sm-flex'>
                                                    <BsThreeDotsVertical onClick={() => setHandleProfilePopup(true)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {children}
                            </Container>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardLayout