import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { Row, Col, Form, Button, Table, Modal } from "react-bootstrap"
import { VALIDATIONS, VALIDATIONS_TEXT, SPECIAL_CHARACTER_ARRAY } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { NavLink, useNavigate } from 'react-router-dom';
import { SlotClock } from '../../constants/svgs';
import { FaTimes } from "react-icons/fa"
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";


const CreateTeamPostCalendarForm = ({subHeading, link}) => {
    const navigate = useNavigate();
    const [time, setTime] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [meridiem, setMeridiem] = useState('');
    const [calendarTemplate, setCalendarTemplate] = useState("0");
    const [showSelectedSlot, setShowSelectedSlot] = useState(false)
    const [handleSchedulePostPopup, setHandleSchedulePostPopup] = useState(false);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = (data) => {
        setTimeout(() => {
            navigate("/social-accounts")
        }, 1500)
    }


    const handleTime = () => {
        let t = hours + ":" + minutes + ":" + meridiem;
        console.log(t);
        setTime(t)
        setValue("scheduleTime", time)
        setShowSelectedSlot(true)
        setHandleSchedulePostPopup(false)
    }

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {
                    handleSchedulePostPopup &&
                    <Modal
                        className='schedule-post-modal'
                        show={true}
                        size="xs"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>
                            <div className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                                <h6 className='p-0 m-0'>Schedule Post Time</h6>
                                <div onClick={() => setHandleSchedulePostPopup(false)} className="d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                            </div>

                            {/* <TimePicker
                                inputReadOnly={true}
                                open={true}
                                // placeholder="Scheduled Post Time"
                                use12Hours
                                showSecond={false}
                                focusOnOpen={true}
                                format="hh:mm A"
                                onChange={e => setTime(e.format('LT'))}
                            /> */}

                            <div className="custom-clock">
                                <div className='clock-holder d-flex justify-content-between'>
                                    <div className="hours">
                                        <ul>
                                            <li onClick={() => setHours("12")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='12'
                                                    className='d-none'
                                                />
                                                <label htmlFor="12" className='d-flex align-items-center justify-content-center text-center label-youtube'>12</label>
                                            </li>
                                            <li onClick={() => setHours("01")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='01'
                                                    className='d-none'
                                                />
                                                <label htmlFor="01" className='d-flex align-items-center justify-content-center text-center label-youtube'>01</label>
                                            </li>
                                            <li onClick={() => setHours("02")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='02'
                                                    className='d-none'
                                                />
                                                <label htmlFor="02" className='d-flex align-items-center justify-content-center text-center label-youtube'>02</label>
                                            </li>
                                            <li onClick={() => setHours("03")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='03'
                                                    className='d-none'
                                                />
                                                <label htmlFor="03" className='d-flex align-items-center justify-content-center text-center label-youtube'>03</label>
                                            </li>
                                            <li onClick={() => setHours("04")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='04'
                                                    className='d-none'
                                                />
                                                <label htmlFor="04" className='d-flex align-items-center justify-content-center text-center label-youtube'>04</label>
                                            </li>
                                            <li onClick={() => setHours("05")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='05'
                                                    className='d-none'
                                                />
                                                <label htmlFor="05" className='d-flex align-items-center justify-content-center text-center label-youtube'>05</label>
                                            </li>
                                            <li onClick={() => setHours("06")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='06'
                                                    className='d-none'
                                                />
                                                <label htmlFor="06" className='d-flex align-items-center justify-content-center text-center label-youtube'>06</label>
                                            </li>
                                            <li onClick={() => setHours("07")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='07'
                                                    className='d-none'
                                                />
                                                <label htmlFor="07" className='d-flex align-items-center justify-content-center text-center label-youtube'>07</label>
                                            </li>
                                            <li onClick={() => setHours("08")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='08'
                                                    className='d-none'
                                                />
                                                <label htmlFor="08" className='d-flex align-items-center justify-content-center text-center label-youtube'>08</label>
                                            </li>
                                            <li onClick={() => setHours("09")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='09'
                                                    className='d-none'
                                                />
                                                <label htmlFor="09" className='d-flex align-items-center justify-content-center text-center label-youtube'>09</label>
                                            </li>
                                            <li onClick={() => setHours("10")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='10'
                                                    className='d-none'
                                                />
                                                <label htmlFor="10" className='d-flex align-items-center justify-content-center text-center label-youtube'>10</label>
                                            </li>
                                            <li onClick={() => setHours("11")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='11'
                                                    className='d-none'
                                                />
                                                <label htmlFor="11" className='d-flex align-items-center justify-content-center text-center label-youtube'>11</label>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="minutes">
                                        <ul>
                                            {
                                                Array.from({ length: 61 }, (_, i) => {
                                                    return (
                                                        <li key={i} onClick={() => setMinutes(i < 10 ? `0${i}` : i)}>
                                                            <input
                                                                name="minutes"
                                                                type="radio"
                                                                id={i < 10 ? `m-0${i}` : `m-${i}`}
                                                                className='d-none'
                                                            />
                                                            <label htmlFor={i < 10 ? `m-0${i}` : `m-${i}`} className='d-flex align-items-center justify-content-center text-center label-youtube'>{i < 10 ? `0${i}` : i}</label>
                                                        </li>
                                                    )
                                                })
                                            }

                                        </ul>
                                    </div>

                                    <div className="am-pm">
                                        <ul>
                                            <li onClick={() => setMeridiem("AM")}>
                                                <input
                                                    name="meridiem"
                                                    type="radio"
                                                    id='AM'
                                                    className='d-none'
                                                />
                                                <label htmlFor="AM" className='d-flex align-items-center justify-content-center text-center label-youtube'>AM</label>
                                            </li>
                                            <li onClick={() => setMeridiem("PM")}>
                                                <input
                                                    name="meridiem"
                                                    type="radio"
                                                    id='PM'
                                                    className='d-none'
                                                />
                                                <label htmlFor="PM" className='d-flex align-items-center justify-content-center text-center label-youtube'>PM</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Button className="btn-done" onClick={() => handleTime()}>Done</Button>
                        </Modal.Body>
                    </Modal>
                }

                <div className='create-team-post-form my-4'>
                    <Row>
                        <Col xs={12} className="d-none custom-d-block mb-3">
                            <div className="text-end">
                                <NavLink className="text-dark me-3" to={`/${link}`}>{subHeading}</NavLink>
                            </div>
                        </Col>

                        <Col xs={12} xl={5}>
                            <div>
                                <p>
                                    Need help picking a strategy?  Try one of our recommended templates.
                                    Start light, and add more as you see fit.
                                    Click drop down to learn more about optimal posting times.
                                </p>
                            </div>
                        </Col>

                        <Col xs={12} xl={{ span: 5, offset: 2 }} >
                            <div>
                                <Form.Label>Select from Calendar Templates</Form.Label>
                                <Form.Select className="py-2" aria-label="Default select example" onChange={(e) => setCalendarTemplate(e.target.value)}>
                                    <option value="0">Light - 3 Posts / week - M, W, F @ 12PM</option>
                                    <option value="1">Custom - Manage days and time by your choice</option>
                                    <option value="2">Medium - 7 Posts / week - M, T, W, Th, F, Sat, Sun @ 12PM</option>
                                    <option value="3">Heavy - 14 / week - M, T, W, Th, F, Sat, Sun @ 9AM & 12PM</option>
                                    <option value="4">5 Posts / week - M, W, F @ 9AM</option>
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-5 mb-sm-0">
                        <Col xs={12} className="mt-2 border-top">
                            <div className='mt-3'>
                                <h4>Custom <small>(Select Days and Time)</small> </h4>
                            </div>
                        </Col>

                        <Col xs={12} className="mt-2">
                            {
                                calendarTemplate === "0" &&
                                <div className='table-0'>
                                    <Table responsive="xl">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Monday</th>
                                                <th>Tuesday</th>
                                                <th>Wednesday</th>
                                                <th>Thursday</th>
                                                <th>Friday</th>
                                                <th>Saturday</th>
                                                <th>Sunday</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='text-grey'><span>9</span> AM</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className='text-grey'><span>10</span> AM</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className='text-grey'><span>11</span> AM</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className='text-grey'><span>12</span> PM</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className='text-grey'><span>1</span> PM</td>
                                                <td><h6>Scheduled Post</h6></td>
                                                <td></td>
                                                <td><h6>Scheduled Post</h6></td>
                                                <td></td>
                                                <td><h6>Scheduled Post</h6></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className='text-grey'><span>2</span> PM</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className='text-grey'><span>3</span> PM</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            }

                            {
                                calendarTemplate === "1" &&
                                <div className='table-1'>
                                    <Table responsive="xl">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Monday</th>
                                                <th>Tuesday</th>
                                                <th>Wednesday</th>
                                                <th>Thursday</th>
                                                <th>Friday</th>
                                                <th>Saturday</th>
                                                <th>Sunday</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='text-grey'><span>9</span> AM</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className='text-grey'><span>10</span> AM</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className='text-grey'><span>11</span> AM</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className='text-grey'><span>12</span> PM</td>
                                                <td>
                                                    {
                                                        showSelectedSlot &&
                                                        <div className='selected-slot'>
                                                            <h6>Selected Slot</h6>
                                                            <FaRegEdit className='me-2 icon' />
                                                            <RiDeleteBin6Line className='icon' />
                                                        </div>
                                                    }
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className='text-grey'><span>1</span> PM</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className='text-grey'><span>2</span> PM</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className='text-grey'><span>3</span> PM</td>
                                                <td>
                                                    <Button className="btn-add-slot" onClick={() => setHandleSchedulePostPopup(true)}>
                                                        <SlotClock />
                                                        <small>Add Post Slot</small>
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button disabled className="btn-add-slot">
                                                        <SlotClock />
                                                        <small>Add Post Slot</small>
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button className="btn-add-slot" onClick={() => setHandleSchedulePostPopup(true)}>
                                                        <SlotClock />
                                                        <small>Add Post Slot</small>
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button className="btn-add-slot" onClick={() => setHandleSchedulePostPopup(true)}>
                                                        <SlotClock />
                                                        <small>Add Post Slot</small>
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button disabled className="btn-add-slot">
                                                        <SlotClock />
                                                        <small>Add Post Slot</small>
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button className="btn-add-slot" onClick={() => setHandleSchedulePostPopup(true)}>
                                                        <SlotClock />
                                                        <small>Add Post Slot</small>
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button disabled className="btn-add-slot">
                                                        <SlotClock />
                                                        <small>Add Post Slot</small>
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            }
                        </Col>

                        <Col xs={12}>
                            <Button type="submit" className="mt-4 btn-continue">CONTINUE</Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
}

export default CreateTeamPostCalendarForm