import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ChangePasswordForm from '../components/account-setup/ChangePasswordForm'
import TeamProfileForm from '../components/account-setup/TeamProfileForm'
import SettingNavigation from '../components/dashboard/SettingNavigation'

const TeamProfilePage = () => {
    return (
        <div className='team-profile-page pb-3'>
            <Row>
                <Col xs={12} className="d-block d-md-none mt-4">
                    <h5 className='text-yellow'>SETTINGS</h5>
                </Col>

                <SettingNavigation />

                <Col xs={12} md={8} lg={6} xl={5} xxl={5} className="mt-4">
                    <TeamProfileForm />
                </Col>
            </Row>
        </div>
    )
}

export default TeamProfilePage