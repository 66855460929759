import React from 'react'
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import SettingNavigation from '../components/dashboard/SettingNavigation'
import { InfoIcon } from '../constants/svgs'
import { useForm } from "react-hook-form";

const AutoPostPage = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = (data) => {
        console.log(data)
    }

    return (
        <div className='auto-post-page pb-3'>
            <Row>
                <Col xs={12} className="d-block d-md-none mt-4">
                    <h5 className='text-yellow'>SETTINGS</h5>
                </Col>

                <SettingNavigation />

                <Col xs={12} lg={6} xl={6} className="mt-4">
                    <div>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='switch-container d-flex align-items-center justify-content-between p-4'>
                                <div className='d-flex align-items-center'>
                                    <h6 className='m-0 p-0 me-4'>Auto Post</h6>
                                    <OverlayTrigger
                                        key="right"
                                        placement="right"
                                        overlay={
                                            <Tooltip style={{ marginTop: "5rem" }} className='text-start'>
                                                <span style={{ fontSize: "14px" }} className='text-dark'>
                                                    Creating a Post Calendar is critical to maintaining
                                                    a uniform strategy, and it keeps you consistent.
                                                    Every time you create or submit a post, you have the
                                                    option to assign it to a future Post slot.  So, design
                                                    your ideal Post Calendar and make sure your slots are
                                                    filled ahead of time! We suggest starting light, and
                                                    adding slots as desired - that way you're not overwhelmed
                                                    from the start.
                                                </span>
                                            </Tooltip>
                                        }
                                    >
                                        <span className="d-none d-lg-inline" type="button">{<InfoIcon />}</span>
                                    </OverlayTrigger>
                                </div>

                                <div className="switch_box box_1">
                                    <input type="checkbox" {...register("autoPost")} className="switch_1" />
                                </div>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AutoPostPage