import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import WebFont from 'webfontloader'
import FontCss from './assets/fonts/fonts.css';
// import "rsuite/dist/rsuite.min.css";
import "./assets/css/styles.css";

WebFont.load(
  {
    google: {
      families: ['Open Sans:300,400,500,600,700']
    },
    custom: {
      families: ['Norwester', 'EuroStyle', 'Eurostile', 'Eurostile Extended'],
      urls: [FontCss]
    }
  }
)
const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

