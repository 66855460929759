import React from 'react'
import Assets from '../../constants/images'
import { AddUserIcon, DoneIconSquare, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, RejectIconSquare, TwitterIcon, YoutubeIcon, YoutubeIconWithoutBg } from '../../constants/svgs'

const PendingSubmissionCard = () => {
    return (
        <div className='pending-submission-card'>
            <div className='d-flex justify-content-between align-items-center border-bottom pb-3 mt-3'>
                <div className='avatar-container d-flex'>
                    <img src={Assets.Avatar} alt="Avatar" />
                    <div className='d-flex flex-column ms-2'>
                        <span>Michelle George</span>
                        <small>4h ago</small>
                        <p className='p-0 m-0'>Winter 21 style forecast: Embroidered Jacketsand silk slips</p>
                    </div>
                </div>

                <div className='ps-3 d-flex align-items-center'>
                    <div className='me-2'><DoneIconSquare /></div>
                    <div><RejectIconSquare /></div>
                </div>
            </div>
        </div>
    )
}

export default PendingSubmissionCard