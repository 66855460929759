import React from 'react'
import { Col, Row } from 'react-bootstrap'
import SettingNavigation from '../components/dashboard/SettingNavigation'

const TermsAndConditionsPage = () => {
    return (
        <div className='terms-and-conditions-page pb-3'>
            <Row>
                <Col xs={12} className="d-block d-md-none mt-4">
                    <h5 className='text-yellow'>SETTINGS</h5>
                </Col>

                <SettingNavigation />

                <Col xs={12} lg={12} xl={9} className="mt-4">
                    <div className='box p-4'>
                        <h6 className='mb-4'>Terms and Condition</h6>
                        <p>
                            This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.
                            <br /><br />
                            These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance devoid of content yet rich in form. That being the case, there is really no point in your continuing to read them. After all, you have many other things you should be doing.
                            <br /><br />
                            Who's paying you to waste this time, anyway?
                            <br /><br />
                            This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.  These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation.
                            <br /><br />
                            Think of them merely a s actors on a paper stage, in a performance devoid of content yet rich in form. That being the case, there is really no point in your continuing to read them.
                        </p>

                        <ul>
                            <li>
                                This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.
                            </li>

                            <li>
                                This is dummy copy. It is not meant to be read.
                            </li>

                            <li>
                                This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.
                            </li>
                        </ul>

                        <p>
                            This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.
                            <br /><br />
                            These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance devoid of content yet rich in form. That being the case, there is really no point in your continuing to read them. After all, you have many other things you should be doing.
                            <br /><br />
                            Who's paying you to waste this time, anyway?
                            <br /><br />
                            This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.  These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation.
                            <br /><br />
                            Think of them merely as actors on a paper stage, in a performance devoid of content yet rich in form. That being the case, there is really no point in your continuing to read them.
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TermsAndConditionsPage